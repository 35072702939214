import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import styles from './style.module.css';
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidLeftArrow } from "react-icons/bi";


const CustomDateHeader = ({ date, decreaseMonth, increaseMonth }) => {
  return (
    <div className={styles.header}>
      <button onClick={decreaseMonth}><BiSolidLeftArrow /></button>
      <span>{format(date, 'MMMM yyyy', { locale: pt })}</span>
      <button onClick={increaseMonth}><BiSolidRightArrow /></button>
    </div>
  );
}

export default CustomDateHeader;