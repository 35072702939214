import React from 'react'
import styles from './style.module.css'

const Loading = ({
  show,
  children,
}) => {
  return (
    show ? (
      <div className={styles.loadingWrapper}>
        <div className={styles.loading}>
          <span className={styles.bar} />
          <span className={styles.bar} />
          <span className={styles.bar} />
          <span className={styles.bar} />
        </div>
      </div>
    )
  : children
  )
}

export default Loading
