import React from 'react';
import { useLocation } from 'react-router-dom';
import PastDueDetailsContainer from '../../../Container/Dashboard/PastDueDetails';

const PastDueDetails = () => {
	const location = useLocation();
	const { state } = location;
	return (
		<PastDueDetailsContainer data={state.data} />
	)
}

export default PastDueDetails;
