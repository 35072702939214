import React from 'react';

function IconStar(props) {
  const {
    fill,
    width,
    height,
  } = props;
  return (
    <svg viewBox="0 0 25 24" width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg">
     <path d="M19.7917 2.25391H5.20833C4.0625 2.25391 3.125 3.10584 3.125 4.14709V17.3994C3.125 18.4407 4.0625 19.2926 5.20833 19.2926H9.375L12.5 22.1324L15.625 19.2926H19.7917C20.9375 19.2926 21.875 18.4407 21.875 17.3994V4.14709C21.875 3.10584 20.9375 2.25391 19.7917 2.25391ZM14.4583 12.5528L12.5 16.4528L10.5417 12.5528L6.25 10.7733L10.5417 8.99365L12.5 5.09369L14.4583 8.99365L18.75 10.7733L14.4583 12.5528Z"/>
    </svg>
    
  );
}

export default IconStar;
