import React, { useState, useEffect } from 'react';
import Card from '../Card';
import DateFilter from '../DateFilter';
import { AiFillQuestionCircle } from 'react-icons/ai';
import IntervalDateFilter from '../IntervalDateFilter';
import styles from './style.module.css';
import { Button } from '../../Components';

export function Tabs({ children, activeTab, onChange, styleType, text, noPadding, onDateRangeChange, customFunction, minHeight }) {
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null, filter: null });

  const handleDateRangeChange = (range) => {
    if (onDateRangeChange) {
      onDateRangeChange(range);
    } else {
      setDateRangeFilter(range);
    }
  };

  useEffect(() => {
  }, [dateRangeFilter]);

  return (
    <Card noPadding={noPadding}>
      <section className={styles.wrapper} style={minHeight ? { minHeight } : {}}>
        {styleType === 'historic' && (
          <div className={styles.tabsTop}>
            <h4> Histórico de atendimentos </h4>
            <div className={styles.filter}>
              <DateFilter onDateChange={handleDateRangeChange} />
            </div>
          </div>
        )}

        {styleType === 'customer' && (
          <>
            <div className={styles.tabsTopReason}>          
              <div className={styles.containerReason}>
                {text && (
                  <>
                    <i><AiFillQuestionCircle /></i>
                    <span className={styles.reason}> Motivo: {text} </span>
                  </>
                )}
              </div>

            </div>
          </>
        )}

        {styleType === 'team' && (
          <div className={styles.tabsTopTeam}>          
            <div className={styles.filterteam}>
              <IntervalDateFilter onDateChange={handleDateRangeChange} />
            </div>
          </div>
        )}
        
        <div className={styles.tabs}>
          {React.Children.map(children, (child, index) => (
            <button
              className={activeTab === index ? styles.active : ''}
              onClick={() => onChange(index)}
            >
              {child.props.label}
            </button>
          ))}

          {styleType === 'customer' && (
            <div className={styles.rightButton}>
              <Button onClick={() => customFunction()} color="dark">
                Atualizar contato hot
              </Button>
            </div>
          )}

        </div>
        <div className={`${styles.tabsContent} ${noPadding && styles.noPadding}`}>
          {React.Children.map(children, (child, index) =>
            activeTab === index ? React.cloneElement(child, { dateRangeFilter }) : undefined
          )}
        </div>
      </section>
    </Card>
  );
}

export default Tabs;
