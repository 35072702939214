import React from 'react'
import classNames from 'classnames'
import styles from './style.module.css'

const Button = ({
  children,
  onClick,
  color,
}) => {

  const colors = {
    default: styles.default,
    white: styles.white,
    dark: styles.dark,
  }

  return (
    <button onClick={onClick} className={classNames([
      styles.btn, colors[color]
    ])}>
      {children}
    </button>
  )
}

export default Button
