import React from 'react';

function IconArrowUp(props) {
  const {
    fill,
    width,
    height,
  } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.74473 1.13356C6.74473 0.859628 6.5191 0.637561 6.24076 0.637561L1.70505 0.637561C1.42671 0.637561 1.20108 0.859628 1.20108 1.13356C1.20108 1.40749 1.42671 1.62956 1.70505 1.62956L5.73679 1.62956L5.73679 5.59756C5.73679 5.87149 5.96243 6.09356 6.24076 6.09356C6.5191 6.09356 6.74473 5.87149 6.74473 5.59756L6.74473 1.13356ZM1.6525 6.35072L6.59712 1.48429L5.8844 0.782836L0.939783 5.64928L1.6525 6.35072Z" fill="#2E5317"/>
    </svg>    
    
  );
}

export default IconArrowUp;
