import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './style.module.css';
import { IoIosClose } from 'react-icons/io';

const Notification = ({ message, onClose, type }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            { type === 'success' &&
             <FaCheckCircle className={styles.successIcon}/>            
            }

            { type === 'error' &&
             <IoIosClose className={styles.errorIcon}/>            
            }
          </div>
          <div className={styles.message}>
            {message}
          </div>
        </div>
        <button className={styles.close} onClick={onClose}>
          <IoIosClose />
        </button>
      </div>
    </div>
  );
};

export default Notification;
