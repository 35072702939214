import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import store from './store'
import { Provider } from 'react-redux'
import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { getCompanySettings } from './services/kollectaApi';
import { pathOr } from 'ramda';

LogRocket.init('0yyung/kollecta-status');

const initializeReactGA = async () => {
  let trackingId;
  const hostname = window.location.hostname.replace('www.', '');

  const locationPath = pathOr(
    'https://portal.kollecta.io',
    ['location', 'href'],
    window,
  );

  const { host } = new URL(locationPath);
  try {
    const { data } = await getCompanySettings(host);
    if (data?.GA) {
      trackingId = data?.GA;
    } else if (hostname === 'kollecta.app') {
      trackingId = 'G-1YX0GGYSMC';

    } else if (hostname.includes('dash')) {
      trackingId = 'G-MYMPMM2H2M';

    } else {      
      const gaId = 'G-MYMPMM2H2M';
      trackingId = gaId;
    }
  } catch (error) {
    console.error('Failed to fetch company settings:', error);
  }

  const tagManagerArgs = {
    gtmId: trackingId,
  };
  
  TagManager.initialize(tagManagerArgs);

  ReactGA.initialize(trackingId);
};

initializeReactGA();

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

ReactGA.pageview(window.location.pathname + window.location.search);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
