import React, { useState } from 'react';
import styles from './style.module.css';
import { useEffect } from 'react';
import Tabs from '../Tabs';
import { Tab } from '../Tab';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import UpdateContactModal from './CustomerModal';

const CustomerData = ({ customerData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(customerData);

  const updateContact = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = (updatedData) => {
    if (updatedData) {
      setData(updatedData);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
  }, [data]);

  return (
    <section className={styles.wrapper}>
      <Tabs activeTab={activeTab} onChange={setActiveTab} styleType='customer' text={data?.reason} noPadding customFunction={updateContact}>
        <Tab label="E-mail">  
          <div className={styles.rows}>          
            <span>
              {data?.emailConfirmed ? data?.emailConfirmed : data?.email } 
            </span>
            {
              data?.emails && data?.emails.map((email) => (
                <span className={styles.contactSpan}>{ email }</span>
              ))
            }
          </div>
        </Tab>
        <Tab label="Celular"> 
          <div className={styles.rows}>
            <span> {formatPhoneNumber(data?.cellPhone) } </span>
            {
              data?.phones && data?.phones.map((phone) => (
                <span className={styles.contactSpan}>{ formatPhoneNumber(phone) }</span>
              ))
            }
          </div>
        </Tab>
      </Tabs>
      <UpdateContactModal isOpen={isModalOpen} onClose={handleCloseModal} data={data} />

    </section>
  )
}

export default CustomerData;
