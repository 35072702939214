import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Button, Layout, Title } from '../../../Components'
import styles from './style.module.css'
import Boleto from '../../../assets/images/boleto.svg'
import { parseCurrency } from '../../../utils/currency'
import { pathOr } from 'ramda'
import GoBack from '../../../Components/GoBack';
import errorImage from '../../../assets/images/error.svg';
import Modal from '../../../Components/Modal';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import BottomModal from '../../../Components/BottomModal'

const PaymentMethods = ({
  goToPayment,
  onChange,
  installmentDay,
  pastDue,
  error,
  loading,
}) => {
  const paymentOptions = [
    // { icon: Pix, label: 'Pix', value: 'pix' },
    { icon: Boleto, label: 'Boleto', value: 'boleto' }
  ]

  const customerName = pathOr(null, ["customerName"], pastDue);
  const firstName = customerName && customerName.split(" ")[0];
  const [errorModal, setErrorModal] = useState(error);
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState();
  let navigate = useNavigate();
  const emailInputRef = useRef(null);

  const bottomModalAction = () => {
    setEmailModal(true);
  }

  const closeModal = () => {
    setEmailModal(false);
  }

  const onChangeInput = (event) => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    setEmail(pastDue.emailConfirmed ? pastDue.emailConfirmed : pastDue.email);
    setErrorModal(error);
  }, [error]);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [email]);

  const containerClass = emailModal ? `${styles.paymentContainer} ${styles.opacityLow}` : styles.paymentContainer;
  
  return (
    <Layout logo={false}>
      {!installmentDay && (
          <div className={containerClass}>
              <div className={styles.top}>
                <div className={styles.goBackButton}>
                  <GoBack />
                </div>
                  <div className={styles.topContent}>
                    <Title size="superLarge" color='white' align='center'>
                      {firstName}
                    </Title>
                    <Title size="small" color="white" align='center'>
                      Você está perto de quitar sua pendência! Pagamento à vista de:
                    </Title>
                    <Title size="superLarge" color="white" align='center'>
                      {parseCurrency(pathOr(0, ["discountAmount"], pastDue))}
                    </Title>
              </div>
          </div>

          <div className={styles.content}>
            <p>
             Não perca esta oportunidade de quitar sua dívida. <span>Clique para gerar
             o boleto. Vamos lá? </span>
            </p>

            <div className={styles.paymentBottom}>
              <img src={Boleto} alt="Kollecta - pagamento em boleto" />
              <div className={styles.paymentContent}>
                <h3> Boleto bancário </h3>
                <span> Valor total: </span>
                <h2>  {parseCurrency(pathOr(0, ["discountAmount"], pastDue))} </h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {installmentDay && (
              <div className={styles.paymentContainer}>
                  <div className={styles.top}>
                    <div className={styles.goBackButton}>
                      <GoBack />
                    </div>
                      <div className={styles.topContent}>
                        <Title size="medium" color='white' align='center'>
                          Você pagará uma entrada no valor de:
                        </Title>
                        <Title size="superLarge" color="white" align='center'>
                          {parseCurrency(pathOr(0, ["entrance"], pastDue))}
                        </Title>
                        <Title size="medium" color="white" align='center'>
                          e {pathOr(0, ["installments"], pastDue)}x de {parseCurrency(pathOr(0, ["installmentAmount"], pastDue))}
                        </Title>
                  </div>
              </div>

              <div className={styles.content}>
                <p>
                Vamos te lembrar de pagar próximo ao vencimento, mas não deixe de <span> pagar a entrada para iniciarmos o processo de quitação de suas dívidas. Vamos lá?</span>
                </p>

                <div className={styles.paymentBottom}>
                  <img src={Boleto} alt="Kollecta - pagamento em boleto" />
                  <div className={styles.paymentContent}>
                    <h3> Boleto bancário </h3>
                    <span> Valor de entrada: </span>
                    <h2>  {parseCurrency(pathOr(0, ["entrance"], pastDue))} </h2>
                  </div>
                </div>
              </div>
            </div>
          )}


      <div style={{ margin: '30px 0', textAlign: 'center' }}>
        <Button onClick={()=> bottomModalAction()} >
          Gerar boleto
        </Button>
      </div>


      {errorModal && (
           <Modal
           onClose={() => setErrorModal(false)}
           doNotCloseOutside
         >
           <div className={styles.modalContainer}>
             <img src={errorImage} alt="warning" />

             <p>
               Não foi possível gerar o boleto. Verifique se o boleto já foi emitido.
             </p>

             <button className={styles.btnSubmit} onClick={() =>  navigate("/offers/customer")}>Consultar novamente</button>
           </div>
         </Modal>
        )}

        {loading && !errorModal && (
           <Modal
           onClose={() => setErrorModal(false)}
           doNotCloseOutside
         >
           <div className={styles.modalContainer}>

             <p>
               Gerando boleto...
             </p>

             <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
           </div>
         </Modal>
        )}

        {emailModal && 
          <BottomModal isOpen={emailModal} handleCloseModal={closeModal}>
            <div className={styles.bottomModalContent}>
              <h3 className={styles.bottomTitle}>
                Confirme o seu e-mail
              </h3>
              <input
                ref={emailInputRef}
                onChange={onChangeInput}
                placeholder="seuemail@email.com" 
                type="email"
                value={email}
                name="email"
                key='email'
                required
              />
              <Button onClick={() => { setEmailModal(false); goToPayment(email); } }>
                Prosseguir
              </Button>

              <p>
              É importante termos seu e-mail para lhe enviarmos lembretes de vencimento e seu boleto em PDF.
              </p>
            </div>
          </BottomModal>

          }
    </Layout>
  )
}

export default PaymentMethods
