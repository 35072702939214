import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { pt } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import styles from './style.module.css';
import CustomDateHeader from '../CustomDateHeader';

function CustomDatePicker({ onDateChange, minDateEnabled }) {
  const [startDate, setStartDate] = useState(new Date());
  const currentDate = minDateEnabled ? new Date() : null;

  const handleChange = (date) => {
    setStartDate(date);
    onDateChange(date); 
  };

  return (
    <DatePicker 
      selected={startDate} 
      onChange={handleChange} 
      className={styles.datePicker}
      popperClassName={styles.popper}
      locale={pt}
      dateFormat="dd/MM/yyyy"
      renderCustomHeader={(props) => <CustomDateHeader {...props} />}
      minDate={currentDate}
    />
  );
}

export default CustomDatePicker;
