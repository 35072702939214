import React, { useState } from 'react'
import PastDueDateContainer from '../../../Container/Mobile/PastDueDate'
import { useNavigate } from 'react-router-dom'

const PastDueDate = () => {
  const navigate = useNavigate()
  const [day, setDay] = useState(1)
  
  const gotToPaymentMethod = () => {
    return navigate(
      `installment-day/${day}`
    )    
  }
  
  return (
    <PastDueDateContainer
      gotToPaymentMethod={gotToPaymentMethod}
      setDay={setDay}
    />
  )
}

export default PastDueDate
