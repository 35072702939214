import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import EffectIcon from './EffectIcon/';


const PortalHeader = () => {
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const rootStyle = getComputedStyle(document.documentElement);
    const logo = rootStyle.getPropertyValue('--portal-logo').trim();
    
    const logoUrl = logo.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
    setLogoUrl(logoUrl);
  }, []);

  return (   
    <section className={styles.wrapper}>
      <img src={logoUrl} alt='Kollecta' className={styles.companyLogo} />
      <EffectIcon  className={styles.effect} />
    </section>
  )
}

export default PortalHeader;
