import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { pt } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import styles from './style.module.css';

function CustomTimePicker({ onTimeChange }) {
  const [selectedTime, setSelectedTime] = useState(new Date());

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    onTimeChange(time); 
  };

  return (
    <div className='timePicker'>
      <DatePicker 
        selected={selectedTime} 
        onChange={handleTimeChange} 
        className={styles.datePicker}
        popperClassName={styles.popper}
        locale={pt}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        renderCustomHeader={() => null}
      />
    </div>
  );
}

export default CustomTimePicker;
