import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import styles from './style.module.css';
import Card from '../../Card';
import { formatToBRL } from 'brazilian-values';
import { getPieChartData } from '../../../services/kollectaApi';
import DateRangeSelector from '../DateRangeSelector';

const PieChartComponent = () => {
  const [renderChart, setRenderChart] = useState(false);
  const [dataChartCount, setDataChartCount] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  
  const getData = async () => {
    try {
      const data = { startDate, endDate };
      const response = await getPieChartData(data);
      setDataChart(response.data.data);
      setDataChartCount(response.data.count);
    } catch (error) {
      console.error('Failed to fetch pie chart data:', error);
    }
  };

  useEffect(() => {
    let timerId; 
    if (!renderChart) {
      timerId = setTimeout(() => setRenderChart(true), 1000); 
    }
    getData(); 

    return () => clearTimeout(timerId); 
  }, [startDate, endDate]);

  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul className={styles.legend}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ margin: '4px 0' }}>
            <svg width="13" height="13" style={{ display: 'inline-block', marginRight: '4px' }}>
              <rect x="0" y="0" width="13" height="13" fill={COLORS[index]} />
            </svg>
            <span><b className={styles.piePercentage}>{entry?.payload?.payload?.percentage}%</b>  {entry?.payload?.payload?.status}</span>
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.tooltip}>
          <p className={styles.intro}>{`${formatToBRL(payload[0].value / 100)}`}</p>
        </div>
      );
    }
    return null;
  };

  const COLORS = ["#334588", "#08B984", "#7300CF", "#03B4DB", "#FF5C00", "#FF9900", "#DF0000", "#828282"];

  const handleDateChange = async (dates) => {
    const { start, end } = dates;
    setStartDate(start);
    setEndDate(end);
    await getData();
  };

  return (
    <Card>
      <section className={styles.wrapper}>
        <div className={styles.topContent}>
          <h1>Segmentação da carteira</h1>
          <div> 
            <DateRangeSelector 
              onDateChange={handleDateChange} 
              startDate={startDate}
              endDate={endDate}
            /> 
          </div>
        </div>
        
        {renderChart && dataChart ? (
          dataChartCount > 0 ? (
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={dataChart}
                  cx="40%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={120} 
                  fill="#8884d8"
                  dataKey="amount"
                  labelLine={true}
                >
                  {dataChart.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} offset={20} />
                <Legend
                  content={CustomLegend}
                  layout='vertical'
                  align='left'
                  verticalAlign='middle'
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p>Nenhum dado disponível para o período especificado. Altere o filtro de datas.</p>
          )
        ) : (
          <p>Carregando...</p>
        )}
      </section>
    </Card>
  )
}

export default PieChartComponent;
