import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';

import { parseCurrency } from '../../../utils/currency';
import { Button, Layout, Title, RadioButtonOffer } from '../../../Components';
import styles from './style.module.css';
import { pathOr } from 'ramda';
import LogRocket from 'logrocket';
import MbaLogo from '../../../assets/companiesLogos/mba.svg';
import moment from 'moment';
import PortalLayout from '../../../Components/PortalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import  currency from './currency.svg';
import  calendar from './calendar.svg';
import { parse } from 'date-fns';
import { dealOffer, postSendMessage, updatePastDue } from '../../../services/kollectaApi';
import { setPastdueTransaction } from '../../../Redux/Reducers/PastDueTransaction';
import Modal from '../../../Components/Modal';
import errorImage from '../../../assets/images/error.svg';
import ReactLoading from 'react-loading';

const Confirmation = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [installmentDay, setInstallmentDay] = useState('');
  const [ error, setError ] = useState(false);
  const [errorModal, setErrorModal] = useState(error);
  const [ loading, setLoading ] = useState();

  const location = useLocation();

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const moreInfoRef = useRef(null);
  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  LogRocket.identify(pastDue.personalNumber);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAcceptTerms = async () => {
    if (!isChecked) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      await goToChoose();
    }
  };

  const customerName = pathOr(null, ["customerName"], pastDue);
  const firstName = customerName && customerName.split(" ")[0];
 
  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
    scrollToMoreInfo();
  };

  const scrollToMoreInfo = () => {
    if (showMoreInfo && moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sendWhatsapp = async (data) => {
    try {
        await postSendMessage({
          pastDueId: pastDue.id,
          email: data.emailConfirmed || data.email,
          barcode: data.pastDueTransactions[0].boleto_barcode,
        });

    } catch(error) {
      console.log('Error to send whatsapp', error);
    }
  }

  const goToChoose = async () => {
    setLoading(true);
  
    const installment = installmentDay > 0 ? installmentDay : undefined;
  
    try {
      if (pastDue.status === 'generated') {
        setLoading(false);
        setError('generated');
        setErrorModal(true);
        return;  
      }
      const { data = {} } = await dealOffer(pastDue.id, paymentMethod, installment);
      dispatch(setPastdueTransaction(data));
  
      // Verifica se o `pastDue` tem `allPastDueDetails`
      const allPastDueDetails = pastDue.allPastDueDetails || [];
      // Atualiza todas as `pastDues` utilizando os IDs e status
      for (const { id, status } of allPastDueDetails) {
        if (status !== 'generated') {
          try {
            await updatePastDue(id, { status: "generated" });
          } catch (error) {
            console.log(`Error updating pastDue ${id}:`, error);
          }
        }
      }
  
      try {
        await sendWhatsapp(data); 
      } catch (error) {
        console.log('Whatsapp error', error);
      }
  
      setLoading(false);
      return navigate(`method/boleto/billetmethods/method/boleto`);
    } catch (error) {
      console.log('Error', error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showMoreInfo && moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [showMoreInfo]);

  useEffect(() => {
  }, [error]);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
;
    const pastDueDateIndex = pathSegments.indexOf('past-due-date');
    if (pastDueDateIndex >= 0 && pathSegments.length > pastDueDateIndex + 1) {
      setPaymentMethod(pathSegments[pastDueDateIndex + 1]);
    }
;
    const installmentDayIndex = pathSegments.indexOf('installment-day');
    if (installmentDayIndex >= 0 && pathSegments.length > installmentDayIndex + 1) {
      setInstallmentDay(pathSegments[installmentDayIndex + 1]);
    }
  }, [location]);

  const containerClass = emailModal ? `${styles.offerContainer} ${styles.opacityLow}` : styles.offerContainer;


  return (
    <PortalLayout>
      {/* # STEP 2  */}
      <div className={containerClass}>
        <div className={styles.top}>
          <div className={styles.topContent}>
            <h1> Antes de concluir, <span>confira os detalhes do seu acordo</span> </h1>
          </div>
        </div>

        <div className={styles.bigCard}>
          <div className={styles.headerCard}>
            <h4>Dívida da <span>{pastDue?.companyDisplayName}</span></h4>
          </div>

          <div className={styles.topCard}>
            <div className={styles.topCardImage}>
              { pastDue && pastDue.companyId === '44aeda9c-25f2-435b-9d6f-4204860e5783' &&
                <img src={MbaLogo} alt="Kollecta" className={styles.compayLogo}></img>              
              }
            </div>

            <div className={styles.topCardProductInfo}>
              <div className={styles.topCardProductInfoItem}>
                <p> Produto negociado:  <h5> { pastDue?.product || '-' } </h5> </p>
                <p> Contrato:  <h5> { pastDue?.contract || '-' } </h5> </p>
                <p> Origem:  <h5> { pastDue?.reason || '-' } </h5> </p>
              </div>
            </div>
          </div>

          <div className={styles.topCardRow}>
            <div className={styles.values}>
              <span> Valor atualizado da dívida: </span>
              <h6> De {parseCurrency(pastDue?.originalAmount)} </h6>
            </div>

            <div className={styles.offValue}>
              <span> {pastDue?.discount}% OFF </span>

              <h6> Por {parseCurrency(pastDue?.discountAmount)} </h6>
            </div>
          </div>

          <div className={styles.options}>
            <section>
              {paymentMethod === 'avista' && 
              <div className={styles.info}>
                <img src={currency} alt='currency' />
                <div className={styles.currencyContent}>
                  <span>
                    Oferta Escolhida
                  </span>
                  <h6>
                    À vista (BOLETO)
                  </h6>
                  <h5>
                    {parseCurrency(pastDue.discountAmount)}
                  </h5>
                </div>
              </div>
              }

            {paymentMethod === 'parcelado' && 
            <>
              <div className={styles.info}>
                <img src={calendar} alt='calendar' />
                <div className={styles.currencyContent}>
                  <span>
                    Data de vencimento do boleto
                  </span>
                  <h5>
                    Todo dia {installmentDay} de cada mês
                  </h5>
                </div>
              </div>

              <div className={styles.info}>
                <img src={currency} alt='currency' />
                <div className={styles.currencyContent}>
                  <span>
                    Oferta Escolhida
                  </span>                  
                  <h6>
                    Parcelado (BOLETO)
                  </h6>
                  <h6>
                    Entrada de {parseCurrency(pastDue?.entrance)}
                  </h6>
                  <h5>
                    {pastDue.installments}x de {parseCurrency(pastDue?.installmentAmount)}
                  </h5>
                </div>
              </div>
            </>
              }
            </section>

          </div>


        <div className={styles.offerCard}>

          <div style={{ margin: "20px 0" }}>
            <Button onClick={() => handleAcceptTerms()} color="dark">
              Gerar acordo
            </Button>
          </div>

          <div style={{ margin: "40px 0" }}>
            <button onClick={toggleMoreInfo} className={styles.linkButton}>
              {showMoreInfo ? 'Menos informações do débito' : 'Mais informações do débito'}
            </button>

            {showMoreInfo && (
              <div ref={moreInfoRef} 
               className={`${styles.moreInfoContainer} ${showMoreInfo ? styles.moreInfoVisible : ''}`}>
                <h4>
                  Produto negociado: <span>{pastDue?.reason}</span>
                </h4>
                <h4>
                  Quem é a empresa que originou o débito? <span>{pastDue?.companyExplanation}</span>
                </h4>
                <h4>
                  Data de origem da dívida: <span>{pastDue?.validDueDate ? moment(pastDue?.validDueDate).format('DD-MM-YYYY') : '-'}</span>
                </h4>
              </div>
            )}
          </div>
        </div>
        

        </div>

        
        <div className={styles.checkboxContainer}>
              
              {showWarning && (
                <div className={styles.warningMessage}>
                  <span role="img" aria-label="Warning">⚠️</span> Dê o aceite para receber as ofertas e informações referente 
  ao seu acordo via WhatsApp para prosseguir.
                </div>
              )}    

              <div className={styles.checkboxInputContainer}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label>Gostaria de receber ofertas e informações referentes 
                           ao meu acordo com a Kollecta via WhatsApp.
                </label>
              </div>
              </div> 
      </div>

      {errorModal && (
           <Modal
           onClose={() => setErrorModal(false)}
           doNotCloseOutside
         >
           <div className={styles.modalContainer}>
             <img src={errorImage} alt="warning" />

             <p>
               Não foi possível gerar o boleto. Verifique se o boleto já foi emitido.
             </p>

             <button className={styles.linkButton} onClick={() =>  navigate("/offers/customer")}>Consultar novamente</button>
           </div>
         </Modal>
        )}

        {loading && !errorModal && (
           <Modal
           onClose={() => setErrorModal(false)}
           doNotCloseOutside
         >
           <div className={styles.modalContainer}>

             <p>
               Gerando boleto...
             </p>

             <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
           </div>
         </Modal>
        )}
    </PortalLayout>
  )
}

export default Confirmation;
