import React from 'react';
import styles from './style.module.css';
import { resetQueueByUser, updateAllPastDueData } from '../../services/kollectaApi';

const Breadcrumb = ({ paths, user }) => {

  const resetQueue = async (id) => {
    await resetQueueByUser(id);
  }


  if (user) {
    resetQueue(user.id);
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {paths.map((path, index) => (
          <li className={styles.breadcrumbItem} key={index}>
            {index === paths.length - 1 ? (
              <span>{path.label}</span>
            ) : (
              <a href={path.link}>{path.label}</a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
