import React from 'react'
import classNames from 'classnames'

import styles from './style.module.css'

const Title = ({
  children,
  size = 'small',
  color = 'default',
  align = 'left',

}) => {

  const sizes = {
    xsmall: styles.xsm,
    small: styles.sm,
    medium: styles.md,
    large: styles.lg,
    extraLarge: styles.xl,
    superLarge: styles.xxl,
  }

  const textAlign = {
    center: styles.center,
    left: styles.left,
    right: styles.right,
  }

  const colors = {
    default: styles.default,
    info: styles.info,
    primary: styles.primary,
    white: styles.white,
    gray: styles.gray,
  }

  return (
    <h1 className={classNames([
      styles.title, 
      sizes[size],
      colors[color],
      textAlign[align]
    ])}>
      {children}
    </h1>
  )
}

export default Title
