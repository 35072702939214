import React from 'react';
import styles from './style.module.css';

const LoadingOverlay = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingIcon}></div>
    </div>
  );
}

export default LoadingOverlay;