import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  settings: { 
    logo: "https://d3i2d7rykfq6oq.cloudfront.net/partners/logo/kollecta-io.png", 
    primaryColor: "#219BE4", 
    secondaryColor: "#88F4FF", 
    siteUrl: "https://portal.kollecta.io"
  }
}

export const companyInitialConfig = createSlice({
  name: 'companyInitialConfig',
  initialState,
  reducers: {
    setCompanySettings: (state, action) => {
      state.settings = action.payload
    },
    reset: (state) => {
      state.currentPastdue = initialState
    },
  },
})

export const { 
  setCompanySettings, 
  reset,
} = companyInitialConfig.actions

export default companyInitialConfig.reducer
