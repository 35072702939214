import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import PaymentContainer from '../../../Container/Mobile/Payment'

const Payment = () => {
  let { method, installmentDay } = useParams();

  const pastDueTransaction = useSelector((state) => state.pastdueTransaction.pastdueTransaction);

  return (
    <PaymentContainer
      paymentMethod={method}
      data={pastDueTransaction}
      installmentDay={installmentDay}
    />
  )
}

export default Payment
