import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import HomeContainer from '../../../Container/Dashboard/Home'
import { getSummaries, getSummariesLast30Days } from '../../../services/kollectaApi'
import { useNavigate } from 'react-router-dom'
import TeamContainer from '../../../Container/Dashboard/Team'

const Team = () => {
	return (
		<TeamContainer />
	)
}

export default Team;
