import React, { useState, useEffect } from 'react';
import CustomDatePicker from '../DatePicker/';
import styles from './style.module.css';
import { HiOutlineSearch } from 'react-icons/hi';

const IntervalDateFilter = ({ onDateChange }) => {
  const [dates, setDates] = useState({ startDate: null, endDate: null, filter: 'interval' });
  const [showIntervalDatePickers, setShowIntervalDatePickers] = useState(false);

  useEffect(() => {
    if (onDateChange) {
      onDateChange(dates);
    }
  }, [dates, onDateChange]);

  const toggleDatePickers = () => {
    setShowIntervalDatePickers(!showIntervalDatePickers);
  };

  const handleStartDateChange = (date) => {
    setDates(prev => ({ ...prev, startDate: date }));
  };

  const handleEndDateChange = (date) => {
    setDates(prev => ({ ...prev, endDate: date }));
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.toggleButton} onClick={toggleDatePickers}>
        Intervalo
      </button>

      {showIntervalDatePickers && (
        <div className={styles.datesModalContainer}>
          <div className={styles.intervalPickerWrapper}>
            <div className={styles.dateContainer}>
              <label>De</label>
              <CustomDatePicker onDateChange={handleStartDateChange} />
            </div>
            <div className={styles.dateContainer}>
              <label>Até</label>
              <CustomDatePicker onDateChange={handleEndDateChange} />
            </div>
            <button className={styles.searchButton} onClick={() => onDateChange(dates)}>
              <HiOutlineSearch color="#fff" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntervalDateFilter;
