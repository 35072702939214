import { useRef, useState } from 'react';
import { uploadFile as uploadFileService } from '../../services/kollectaApi';
import styles from './style.module.css';
import Icon from './../../assets/images/iconnew.svg';
import Modal from '../Modal';
import ReactLoading from 'react-loading';
import success from '../../assets/images/success.svg';
import error from '../../assets/images/error.svg';


const UploadFile = ({ showList, dataForm, setDataFormAfterUse }) => {
  const filesElement = useRef(null);
  const [files, setFiles] = useState([]);
  const [popUpFeedBack, setPopUpFeedBack] = useState({
    loading: false,
    datesImported: false,
    success: false,
    apiError: false,
    downloadError: false,
    typeError: false,
    totalImported: 0,
    total: 0
  });


  const sendFile = async () => {
    setPopUpFeedBack({ ...popUpFeedBack, loading: true })
    try {
      const dataForm = new FormData()
      for (const file of filesElement.current.files) {
        setFiles([...files, file])
        dataForm.append('file', file)
      }
      const res = await uploadFileService(dataForm)
      setPopUpFeedBack({ ...popUpFeedBack, success: true, loading: false, totalImported: res.data.total, total: res.data.documentTotal })
    } catch (error) {
      setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, typeError: true });
    }
  }


  const handlePostWallet = async () => {
    try {
      for (const file of filesElement.current.files) {
        setFiles([...files, file])

        dataForm.append('file', file)
        setDataFormAfterUse(dataForm)
      }

    } catch (error) {
      alert(`Não foi possível importar o arquivo!`)
    }
  }


  return (
    <div className={styles.uploadFileContainer}>
      <div className={styles.contentInputFile}>
        <div class={styles.inputFileUploadContainer}>
          <input className={styles.inputFile} id="my-file" type="file" accept=".csv, .xlsx" onChange={showList ? sendFile : handlePostWallet} ref={filesElement} />
          <label tabindex="0" for="my-file" className={styles.inputFileTrigger}>
            <span className={styles.triggerIcon}><img className={styles.inputIcon} src={Icon} alt="upload files" /></span>
            Arraste seu arquivo em XLSX(Excel) aqui
          </label>
        </div>

        <div className={styles.inputActions}>
          <h5>ou</h5>
          <label tabindex="0" for="my-file" className={styles.btnUpload}>
            Buscar arquivo
          </label>
        </div>

      </div>

      {showList && (
        <div className={styles.inputListFiles}>
          <div className={styles.fileItens}>
            {files.map((file, index) => (
              <div className={styles.fileItem}>
                <div key={index}>
                  <h3 className={styles.itemNameFile}>{file.name}</h3>
                  <h4 className={styles.itemSizeFile}>{file.size > 999 ? file.size + ' mb' : file.size + ' kb'}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Modal */}
      {popUpFeedBack.loading && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, loading: !popUpFeedBack.loading })}
          >
            <div className={styles.modalContainer}>
              <p>
                Sua análise está em processamento e pode demorar alguns minutos para ser concluída
              </p>
              <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
            </div>
          </Modal>
        )}

      {popUpFeedBack.success && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, success: !popUpFeedBack.success })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              <img src={success} alt="success" />
              <p>
                Foram importados {popUpFeedBack.totalImported} de {popUpFeedBack.total} enviados!
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}   


        {popUpFeedBack.typeError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, apiError: false })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Não foi possível importar o arquivo!
                Código do  erro: KC-001
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}


    </div>
  )
}

export default UploadFile
