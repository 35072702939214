import React, { useState } from 'react';
import styles from './style.module.css';


const InputSimple = ({ onValueChange, placeholder, isCurrency, label, inputType, fullWidth, small  }) => {
  const [value, setValue] = useState('');
  const [displayValue, setDisplayValue] = useState('');

  const formatCurrency = (value) => {
    if (!value) {
      return '';
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(parseFloat(value) / 100);
  };

  const parserCurrencyValue = (value = '') => {
    if (value === null) {
      value = 0;
    }
    if (typeof value === 'object' && value.result) {
      value = value.result;
    }

    let num;
    if (typeof value !== 'string') {
      num = parseFloat(value);
    } else {
      num = parseFloat(value.replace(/[^\d.,-]+/g, '').replace(',', '.'));
    }

    return Math.round(num * 100);
  }

  const handleFocus = () => {
    if (value) {
      setDisplayValue(value.replace('.', ',')); 
    } else {
      setDisplayValue('');
    }
  }

  const handleBlur = () => {
    if (isCurrency) {
      const parsedValue = parserCurrencyValue(value);
      setDisplayValue(parsedValue ? formatCurrency(parsedValue) : '');
    } else {
      setDisplayValue(value);
    }
  }
  
  const handleChange = (event) => {
    const inputValue = event.target.value;

    if (!inputType) {
      if (!inputValue || /^[0-9.,]+$/.test(inputValue)) {
        const numericValue = inputValue.replace(',', '.');
        setValue(numericValue);
        setDisplayValue(inputValue);
  
        if (onValueChange) {
          onValueChange(parserCurrencyValue(numericValue));
        }
      }
    } else if (inputType === 'text') {
      setValue(inputValue);
      setDisplayValue(inputValue);
      if (onValueChange) {
        onValueChange(inputValue);
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      {label && <label htmlFor="inputSimple">{label}</label>}
      <input 
        id="inputSimple"
        type="text"
        value={displayValue} 
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={((fullWidth && styles.fullWidth) || (small && styles.small)) || ''}
      />
    </div>
  );
}

export default InputSimple;

