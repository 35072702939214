import React from 'react'
import styles from './style.module.css'

const RadioButton = ({
  icon,
  value,
  description,
  onChange,
  customStyle,
  checked
}) => {
  return (   
    <label>
      <input type="radio" name="product" className={styles.cardInputElement} value={value} onChange={onChange} checked={checked} />
      <div className={`${styles.cardInput} ${customStyle === 'column' ? styles.column : ''}`}>
        <div className={styles.radioCircle}>
          <div className={styles.radioDot} />
        </div>
        <div className={styles.icon}>
          {icon && <img className={styles.imagePosition} src={icon} alt="payment-options" height="60px" />}
          {description && <p className={styles.description}>{description} </p>}
        </div>
      </div>
    </label>
  )
}

export default RadioButton
