import React, { useEffect, useState } from 'react';
import { 
  Button, 
  Input, 
  Title, 
  Loading,
} from '../../../Components';
import styles from './style.module.css';
import KollectaLogo from '../../../assets/images/kollectanew.svg';
import PortalFooter from '../../../Components/PortalFooter';
import FooterBigEllipse from './FooterBigEllipse';
import FooterSmallEllipse from './FooterSmallEllipse';
import HeaderBigEllipse from './HeaderBigEllipse';
import HeaderSmallEllipse from './HeaderSmallEllipse';
import KollectaIcon from './KollectaIcon';

const Home = ({
  handleChange,
  goToOffer,
  personalNumber,
  settings,
  loading,
  companySettings,
}) => {

  return (
  //
  // Todo - Dividir em dois componentes
  //

    <Loading show={loading}>
    {/* Whitelabel */}
    { localStorage.getItem('primaryColor') &&
        <>
          <div className={styles.whitelabelHeaderEllipses}>
            <HeaderBigEllipse />
          </div>
          <div className={styles.whitelabelHeaderLeftEllipses}>
            <HeaderSmallEllipse />
          </div>
        </>
      }
    <div className={`${styles.homeContainer}`}>
        <div className={styles.homeSize}>

          {/* Kollecta */}
          { !localStorage.getItem('primaryColor') &&
            <>
              <div className={styles.layoutHeader}>
              <img
                className={styles.layoutLogoMobile}
                src={KollectaLogo}
                alt={settings.siteUrl} />
              </div>
           
              <div className={styles.containerLimit}>
                  <div style={{ margin: '50px 0 30px 0' }}>
                    <Title size="superLarge" color="primary" textAlign="left">
                      Suas dívidas pagas agora
                    </Title>
                  </div>
                  <Title size="medium" color="primary" textAlign="left">
                    Simples e fácil.
                  </Title>

                  <Title size="medium" color="primary" textAlign="left">
                    De forma 100% digital.
                  </Title>
                </div>
                
                <div style={{ margin: '30px 0 20px 0' }}>
                  <Input
                    name="personalNumber"
                    value={personalNumber}
                    placeholder="Digite o seu CPF ou CNPJ"
                    onChange={handleChange}
                    mask="999.999.999-99" />
                </div>
                
                <div style={{ margin: '0 0 50px 0' }}>
                  <Button onClick={goToOffer}>
                    CONSULTAR AGORA
                  </Button>
                </div>
              </>  
          }             

          {/* Whitelabel */}
          { localStorage.getItem('primaryColor') &&
            <section>
              <div className={styles.whitelabelTop}>
                <h1> Negocie suas dívidas online, </h1>
                <b> sem complicações </b>
              </div>

              <div className={styles.whitelabelMiddle}>
                <p>
                  <span> Kollecta <KollectaIcon /> </span>
                  <span> parceira </span>
                  <span> oficial da </span>
                </p>
                <img
                  className={styles.layoutLogoMobile}
                  src={localStorage.getItem('logo') || '/kollecta.svg'}
                  alt={settings.siteUrl} />
              </div>

              <div style={{ margin: '30px 0 20px 0' }}>
                  <Input
                    name="personalNumber"
                    value={personalNumber}
                    placeholder="Digite o seu CPF ou CNPJ"
                    onChange={handleChange}
                    mask="999.999.999-99" />
                </div>
                
                <div style={{ margin: '0 0 50px 0' }}>
                  <Button onClick={goToOffer}>
                    Consultar grátis
                  </Button>
                </div>
            </section>
          }      
        </div>
        

        {/* Kollecta */}
        {!localStorage.getItem('primaryColor') &&
          <div className={styles.footer}>
                      
          </div>
        }

        {/* Whitelabel */}
        { localStorage.getItem('primaryColor') &&
          <>
            <div className={styles.whitelabelFooterEllipses}>
              <FooterBigEllipse />
              <FooterSmallEllipse />
            </div>
            <PortalFooter />
          </>
        }       

    </div>
    </Loading>
 )
}

export default Home
