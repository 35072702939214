import React, { useState } from 'react';
import styles from './style.module.css'; // Ajuste o caminho para o seu arquivo CSS

const DropdownMenu = ({ queueFunction }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className={styles.dropdown}>
       <button onClick={toggleDropdown} className={styles.dropdownButton}>
        Opções <span className={isOpen ? styles.arrowUp : styles.arrowDown}>▼</span>
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          <a onClick={() => queueFunction(true)}>Criar Fila</a>
          {/* <a href="#editar">Editar</a>
          <a href="#emitir-boleto">Emitir boleto</a>
          <a href="#confissao-divida">Confissão de dívida</a>
          <a href="#mandar-mensagem">Mandar mensagem</a> */}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
