import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import { updateAllPastDueData, createContactLog } from '../../../services/kollectaApi';

const UpdateContactModal = ({ isOpen, onClose, data }) => {
  const [emailToUpdate, setEmailToUpdate] = useState('');
  const [cellphoneToUpdate, setCellphoneToUpdate] = useState('');
  const [observations, setObservations] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);


  const formatToPhone = (value) => {
    const numbers = value.replace(/[^\d]/g, '');
    const masked = numbers.replace(
      /(\d{2})(\d{5})(\d{4})/,
      '($1) $2-$3'
    );
    
    return masked;
  };

  const handleCellphoneChange = (e) => {
    const numbers = e.target.value.replace(/[^\d]/g, '')
    setCellphoneToUpdate(numbers);
  };

  const displayMaskedCellphone = (cellphone) => {
    if (cellphone.length <= 11) {
      return formatToPhone(cellphone);
    }
    return cellphone;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToUpdate = {};
    const dataLog = {};
    
    if (cellphoneToUpdate.trim()) {
      dataToUpdate.cellPhone = cellphoneToUpdate;
      dataLog.newContact = cellphoneToUpdate;
      dataLog.oldContact = data.cellPhone;
      dataLog.type = 'cellphone';
    }
  
    if (emailToUpdate.trim()) {
      dataToUpdate.email = emailToUpdate;
      dataLog.newContact = emailToUpdate;
      dataLog.oldContact = data.email;
      dataLog.type = 'email';
    }

    dataLog.description = observations ?? null;
    dataLog.pastDueId = data.id;
  
    if (Object.keys(dataToUpdate).length === 0) {
      return;
    }
  
    try {
      const updateData = await updateAllPastDueData(data.id, dataToUpdate);
      setFeedbackMessage('Contato atualizado com sucesso!');
      await createContactLog(dataLog);
      setDataUpdated(updateData);
      onClose(updateData?.data);
      setFeedbackMessage('');
      setIsError(false);
    } catch (error) {
      console.log('Erro ao atualizar contato', error);
      setFeedbackMessage('Erro ao atualizar contato. Por favor, tente novamente.');
      setIsError(true);
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modalBackground') {
      onClose();
    }
  };

  useEffect(() => {
  }, [data, dataUpdated]);

  if (!isOpen) {
    return null;
  }

  return (
    <div id="modalBackground" className={styles.modalBackground} onClick={handleOutsideClick}>
      <div className={styles.modal}>
        <form onSubmit={handleSubmit}>
          <h2>Atualização contato hot</h2>
          <div className={styles.modalTop}>
            <div className={styles.modalTopContent}>
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                type="email"
                value={emailToUpdate}
                onChange={(e) => setEmailToUpdate(e.target.value)}
              />
            </div>

            <div className={styles.modalTopContent}>
              <label htmlFor="cellphone">Celular</label>
              <input
                id="cellphone"
                type="text"
                value={displayMaskedCellphone(cellphoneToUpdate)}
                onChange={handleCellphoneChange}
              />
            </div>
          </div>

          <div className={styles.modalContent}>
            <label htmlFor="observations">Observações</label>
            <textarea
              id="observations"
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              required
            />
            <button type="submit">Atualizar</button>
            {feedbackMessage && (
              <div className={isError ? styles.errorMessage : styles.successMessage}>
                {feedbackMessage}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateContactModal;
