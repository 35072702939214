import React from 'react';
import styles from './style.module.css';

const BottomModal = ({ isOpen, handleCloseModal, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <button className={styles.closeButton} onClick={() => handleCloseModal()}>X</button>
      </div>
      {children}
    </div>
  );
};

export default BottomModal;