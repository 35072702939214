import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import BilletMethodsContainer from '../../../Container/Mobile/BilletMethods';

const BilletMethods = () => {
  const [billetMethod, setBilletMethod] = useState('');
  const [payment, setPayment] = useState('boleto')
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()


  const companySettings = useSelector((state) => state.companySettings.settings);
  let { offerId, installmentDay } = useParams();

  
  const goToCodeOrEmail = async() => {
    try {
      if (billetMethod === 'email') {
        navigate(`email`);        
      } else if (billetMethod === 'barcode') {
        navigate(`method/${payment}`)
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }
  return (
    <BilletMethodsContainer
      setBilletMethod={setBilletMethod}
      goToCodeOrEmail={goToCodeOrEmail}
      settings={companySettings}
      loading={loading}
      installmentDay={installmentDay}
    />
  )
}

export default BilletMethods;
