import React from 'react'
import styles from './style.module.css'
import InputMask from 'react-input-mask'

const Input = ({
  name,
  placeholder,
  value,
  onChange,
  mask
}) => {

  return (
    <InputMask 
      className={styles.input}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      mask={value.length < 15 ? "999.999.999-999" : "99.999.999/9999-99"}
      maskChar=""
    />
  )
}

export default Input 
