import React from 'react';

export function Tab({ children, dateRangeFilter }) {  
  const enhancedChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { dateRangeFilter });
    }
    return child;
  });

  return <div>{enhancedChildren}</div>;
}