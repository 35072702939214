import React from 'react';
import styles from './style.module.css';
import { TbRefresh  } from 'react-icons/tb';
import { HiArrowRight  } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { updateAllPastDueData } from '../../services/kollectaApi';

const ActionButton = ({ type, data }) => {
  const navigate = useNavigate();

  const removeTemp = async () => {
    await updateAllPastDueData(data.id, { inAttendance: false, user: null });
  }

  const reloadPage = async () => {
    if (data && data.status === 'waiting_payment') {
      await removeTemp();
    }
    window.location.reload();
  }

  const directToDetails = () => {
    navigate('/dashboard/details', { state: { data } });
  }

  if (type === 'refresh') {
    return (
      <button className={styles.wrapper} onClick={reloadPage}>
        <TbRefresh width={27} height={27} />     
      </button>
    )
  }

  return (
    <button className={styles.wrapper} onClick={directToDetails}>
      <HiArrowRight width={27} height={27} />      
    </button>
  )
}

export default ActionButton;
