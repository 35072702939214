import React, { useState } from "react";
import { useEffect } from "react";
import styles from './style.module.css';
import { dealOffer, updatePastDue } from '../../services/kollectaApi';
import { parseCurrency } from '../../utils/currency';
import { IoIosClose } from "react-icons/io";

const IssueInvoice = ({ data, closeModal, setFeedback }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isParceladoSelected, setIsParceladoSelected] = useState(false);
  const [dueDate, setDueDate] = useState(undefined);


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setIsParceladoSelected(e.target.value === 'parcelado');
    setDueDate(undefined);
  };

  const handleDueDateChange = (e) => {
      setDueDate(e.target.value);
  };

  const handleButtonClick = async () => {
    console.log('Data de vencimento selecionada:', dueDate);

    const user = JSON.parse(localStorage.getItem('user'));
    
    if (!user || !user.id) {
      console.error('Usuário não encontrado ou ID do usuário ausente');
      return setFeedback('error');
    }
   
    const response = await dealOffer(data?.id, 'boleto', dueDate, user.id);
    
    if (response.status === 200) {
      const status = { status: "generated" };
  
      try {
        await updatePastDue(data?.id, status);
        console.log('Status updated successfully');  
      } catch (error) {
        console.error('Erro ao atualizar status ou issued_by', data?.id);
      }
  
      return setFeedback('success');
    }
  
    return setFeedback('error');
  };  

  useEffect(() => {
  }, [data]);


  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
       <h2>Emitir boleto</h2>
       <button onClick={() => closeModal(false)} className={styles.close}><IoIosClose /></button>
      </div>
      
      <div>
          <h3>Escolha a oferta</h3>

          <div className={styles.radioItem}>
            <input 
                type="radio" 
                id="avista" 
                value="avista"
                checked={selectedOption === 'avista'}
                onChange={handleOptionChange}
            />
            <div className={styles.radioTexts}>
              <label htmlFor="avista"> Pagamento à vista:  <b>{parseCurrency(data.discountAmount)}</b></label>
              <span>  { data.discount && `(${data.discount / 100}% de desconto)`} </span>
            </div>
          </div>

          <div className={styles.radioItem}>
            <input 
                type="radio" 
                id="parcelado" 
                value="parcelado"
                checked={selectedOption === 'parcelado'}
                onChange={handleOptionChange}
            />
            <div className={styles.radioTextsParcelado}>
              <label htmlFor="parcelado"> Parcelado:<br />  <b> Entrada de: {parseCurrency(data?.entrance)} - {data?.installments}x {parseCurrency(data?.installmentAmount)}</b></label>
            </div>
          </div>
      </div>

      {isParceladoSelected && (
        <div className={styles.dueDate}>
          <h3>Escolha a data de vencimento</h3>
          <div className={styles.radioItem}>
            <input 
                type="radio" 
                id="dia1" 
                name="vencimento" 
                value="1"
                onChange={handleDueDateChange}
            />
            <div className={styles.radioTexts}>
              <label htmlFor="dia1"> Todo dia 1º de cada mês </label>
            </div>
          </div>

          <div className={styles.radioItem}>
            <input 
                type="radio" 
                id="dia5" 
                name="vencimento" 
                value="5"
                onChange={handleDueDateChange}
            />
            <div className={styles.radioTexts}>
              <label htmlFor="dia5"> Todo dia 5 de cada mês </label>
            </div>
          </div>

          <div className={styles.radioItem}>
            <input 
                type="radio" 
                id="dia10" 
                name="vencimento" 
                value="10"
                onChange={handleDueDateChange}
            />
            <div className={styles.radioTexts}>
              <label htmlFor="dia10"> Todo dia 10 de cada mês </label>
            </div>
          </div>
        </div>
      )}
      <button onClick={handleButtonClick} className={styles.generalButton}>Emitir boleto</button>

    </section>
  );
};

export default IssueInvoice;
