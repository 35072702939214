import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import OperatorHomeContainer from '../../../../Container/Dashboard/Operator/Home';
import { getSummaries, getSummariesLast30Days } from '../../../../services/kollectaApi';
import { useNavigate } from 'react-router-dom';

const OperatorHome = () => {
  const [data, setData] = useState([])
  const [last30Day, setLast30Day] = useState([])
  let navigate = useNavigate()

  const getSummariesHome = async () => {
    try {
      const response = await getSummaries();

      if (response.status === 200) {
        const last30DayResponse = await getSummariesLast30Days()
        setData(pathOr([], ['data'], response))
        setLast30Day(pathOr([], ['data'], last30DayResponse))
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem('token');
      navigate("/login")
      return;
    }
  }
  
  useEffect(() => {
    getSummariesHome()
  }, [])

	return (
		<OperatorHomeContainer
      data={data}
      last30Day={last30Day}
    />
	)
}

export default OperatorHome;
