import styles from "./style.module.css";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactLoading from 'react-loading';
import warning from '../../../../assets/images/warning.svg'
import error from '../../../../assets/images/error.svg'
import success from '../../../../assets/images/success.svg'


import isChecked from "../../../../assets/images/isChecked.png";

import { AiOutlineSearch } from 'react-icons/ai'

import { useCallback, useEffect, useState } from "react";
import {
  getCurrencyValue,
  getFilters,
  getWallet,
  downloadWalletSingle,
  postCreditsUpdate,
  postWalletsConfirmSingle,
  postWalletsSingle,
} from "../../../../services/kollectaApi";

import { formatToBRL } from "brazilian-values";
import Modal from "../../../../Components/Modal";

const PortfolioAnalysisSingle = () => {

  const user = JSON.parse(localStorage.getItem("user"));
  const [credit, setCredit] = useState("0");
  const [document, setDocument] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [historyAnalysts, setHistoryAnalysts] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState([]);
  const [filtersToShow, setfiltersToShow] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [dataFormAfterUse, setDataFormAfterUse] = useState();
  const [documentUrl, setDocumentUrl] = useState();
  const [popUpFeedBack, setPopUpFeedBack] = useState({
    loading: false,
    datesImported: false,
    success: false,
    error400: false,
  });
  const [cost, setCost] = useState({
    documents: 0,
    haveCredits: false,
    unitCost: 0,
  });

  const descriptions = {
    ondemand_query_pesquisaprotesto: "A Consulta de Protesto retorna, quando não existem protestos de títulos contra o CPF/CNPJ consultado, uma certidão confirmando esse fato, emitida pelo Instituto de Estudos de Protesto de Títulos do Brasil, a organização de classe dos cartórios de protesto de títulos do Brasil. Quando existe algum protesto, são retornadas informações dos diferentes cartórios aonde existem os protestos, bem como informações específicas dos títulos protestados, como a empresa que realizou o protesto, e o valor do título."
  }

  const setDescription = (items) => {
    items.map((item) => {
      if (item.dataset === 'ondemand_query_pesquisaprotesto') {
        item.description = descriptions.ondemand_query_pesquisaprotesto;
      }
    });
  }

  const onChange = ({target}) => {
    setDocument(target.value.replace(/[^0-9]/g,''));
  }

  const handleAddFilter = (id) => {
    if (!id) return;

    filtersToShow.forEach((item) => {
      if (item.id === id) {
        setFiltersSelected([...filtersSelected, item]);
        return;
      }
      return item;
    });
  };

  const handleRemoveOption = useCallback(
    (id) => {
      filtersSelected.filter((item) => item.id === id);
      setFiltersSelected(filtersSelected.filter((item) => item.id !== id));
    },
    [filtersSelected]
  );

  const handleGenerateAnalysis = useCallback(async () => {
    if (filtersSelected[0].name === 'CNPJ 360° Kollecta' && document.length < 14) {
      return alert(
        "Favor digitar um CNPJ"
      );
    }
    if (!document || filtersSelected.length < 1) {
      return alert(
        "Antes de efetuar uma análise, é necessário digitar um CPF e selecionar ao menos um filtro"
      );
    }

    if (document.length > 11 && filtersSelected[0].name !== 'CNPJ 360° Kollecta') {
      return alert(
        "Antes de efetuar uma análise, é necessário digitar um CPF válido e selecionar ao menos um filtro"
      );
    }
    
    const array = filtersSelected.map((item) => item.dataset);

    const string = array.join(",");

    setIsLoading(true);

    try {
      const response = await postWalletsSingle(
        user.companyId,
        string,
        document
      ).then((data) => {
        setCost(data.data);
        setToggleModal(!toggleModal);
  
        if (!data.data.haveCredits) {
          setfiltersToShow([]);
          setDataFormAfterUse("");
        }
        console.log('Get postWalletsSingle succefully!');
        console.log(data);
        setIsLoading(false);

      });

    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [
    document,
    filtersSelected,
    popUpFeedBack,
    user.companyId,
    toggleModal,
  ]);

  const generateAnalysis = async () => {
    const array = filtersSelected.map((item) => item.dataset);
    const string = array.join(",");
    const data = {
      value: parseFloat(credit.value) - parseFloat(cost.unitCost) * parseFloat(cost.documents + 1),
      companyId: user.companyId,
    };
  
    console.log("Init postCreditsUpdate");
    await postCreditsUpdate(data)
      .then((response) => {
        console.log("Init postWalletsConfirmSingle", response);
        return postWalletsConfirmSingle(user.id, user.companyId, string, document);
      })
      .then((confirmSingleResponse) => {
        if (confirmSingleResponse.data.result === false) {
          setCost({
            documents: 1,
            haveCredits: false,
            unitCost: 0,
          });
        }
        console.log("Init handlePostDownloadFile", confirmSingleResponse);
        return handlePostDownloadFile(confirmSingleResponse.data.fileId);
      })
      .then((downloadFile) => {
        console.log("Finally", downloadFile);
        setToggleModal(false);
        setIsLoading(false);
        setPopUpFeedBack({ ...popUpFeedBack, success: true, loading: false });
        setDocumentUrl(downloadFile.data.result);
      });
  };

  const handleAcceptAnalyst = useCallback(async () => {
    setIsLoading(true);
    setPopUpFeedBack({ ...popUpFeedBack, loading: true });
    setToggleModal(false);
  
    try {
      await generateAnalysis();
    } catch (error) {
      setIsLoading(false);
      setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false });
      if (error?.data && error?.status === 400) {
        setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, error400: true });
        const date = new Date();
        console.log("CPF 400 - Not found", date);
      } else {
        await reconnectHandleAcceptAnalyst().then((data) => {
          return data;
        }).catch(() => {
          setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, error400: true });
        });
      }
    }
  
    setIsLoading(false);
  }, [popUpFeedBack, filtersSelected, credit.value, cost.unitCost, cost.documents, user.companyId, user.id, dataFormAfterUse]);

  const reconnectHandleAcceptAnalyst = async () => {
    handleAcceptAnalyst('reconnect');
  };

  const handlePostDownloadFile = useCallback(async (id) => {
    try {
      const response = await downloadWalletSingle(id);
      return response;
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  const handleGetCurrency = useCallback(async () => {
    try {
      const response = await getCurrencyValue(user.companyId);
      setCredit(response.data);
    } catch {
      alert("Erro ao buscar o credito");
    }
  }, [user.companyId, setCredit]);

  const handleGetFilters = useCallback(async () => {
    try {
      const response = await getFilters();
      var reduced = [];

      response.data.forEach((item) => {
          var duplicated  = reduced.findIndex(oldItem => {
              return item.name === oldItem.name;
          }) > -1;

          if(!duplicated) {
              reduced.push(item);
          }
      });

      const newOrder = reduced.sort((p1, p2) => (p1.name < p2.name) ? -1 : (p1.name > p2.name) ? 1 : 0);
      setDescription(newOrder);
      setfiltersToShow(newOrder);
    } catch {
      alert("Erro ao buscar o credito");
    }
  }, []);

  const handleGetHistoryAnalysts = useCallback(async () => {
    try {
      const response = await getWallet(user.id);
      let data = [];
      response.data.map((item) => {
        if (item.receiveFile && (item.receiveFile.type === 'pdf')) {
          data.push(item);
        }
      });
      setHistoryAnalysts(data);
    } catch(error) {
      alert("Erro ao buscar histórico");
      console.log('Error: ', error);
    }
  }, [user.id]);

  useEffect(() => {
    handleGetCurrency();
    handleGetHistoryAnalysts();
    handleGetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
      <div className={styles.container}>
        <div className={styles.headerContent}>
          <h1 className={styles.title}>Análise de dados</h1>

          <div className={styles.availableCredit}>
            <p>Créditos disponivels para análise</p>

            {credit.value ? (
              <span>{formatToBRL(credit.value)}</span>
            ) : (
              <span>{0}</span>
            )}
          </div>
        </div>

        <div className={styles.generalInfos}>
          <p>
            Olá, <span>{user.name}</span> Por aqui você faz o consultas avulsas
            à partir de CPFs ou CNPJs.
          </p>

          <p>
            Antes de realizar suas análises,{" "}
            <span>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  "https://kollecta-wallet.s3.sa-east-1.amazonaws.com/wallet/Exemplo.csv"
                }
              >
                {" "}
                clique neste link
              </a>
            </span>{" "}
            e entenda os aspectos de cada filtro que pode ser ultilizado
          </p>
        </div>

        <div className={styles.steps}>

                  <div className={styles.analyticsFilter}>
                    <div className={styles.headerFilters}>
                      <h2>1. Filtro de análise</h2>
        
                      <div className={styles.select}>
                        <select
                          onChange={(e) => handleAddFilter(e.target.value)}
                          className={styles.select}
                        >
                          <option value="" selected>
                            Selecione os critérios de sua análise
                          </option>
        
                          {filtersToShow.map((item) => (
                            <option
                              key={item.id}
                              disabled={filtersSelected.some(
                                (filterSelected) => filterSelected.id === item.id
                              )}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
        
                    <div className={styles.selectedFilters}>
                      <p>Filtros selecionados</p>
        
                      <div className={styles.cardSelectedFilters}>
                        {filtersSelected.map((item) => (
                          <div key={item.id}>
                            <span> {item.description ? item.description : item.name } </span>
                            {loading ? (
                              <></>
                            ) : (
                              <span onClick={() => handleRemoveOption(item.id)}>X</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>



          <div className={styles.containerRight}>
            <div className={styles.containerWithTitle}>
              <h2>2. Consulta avulsa</h2>


              <form className={styles.inputGroup} onSubmit={() => handleGenerateAnalysis()}>

                <input placeholder="Digite aqui um CPF ou CNPJ" value={ document } onChange={ onChange }/>
                {loading ? (
                <button className={styles.btnSubmit} disabled>Carregando...</button>
              ) : (
                <button  type="submit" className={styles.btnSubmit} onClick={() => handleGenerateAnalysis()}><AiOutlineSearch /></button>
              )}
              </form>

            </div>


            <div className={styles.historyContainer}>
            <h2>3. Histórico de Análises</h2>
            <div className={styles.historySection}>
              {historyAnalysts.map((item) => (
                <div
                  className={styles.historyContent}
                  key={item.id}
                  onClick={() => {
                    window.open(
                      `https://kollecta-wallet.s3.sa-east-1.amazonaws.com/wallet/${item.id}.pdf`
                    );
                    //
                  }}
                >
                  <div className={styles.historyDetails}>
                    <span>{item.name}.pdf</span>
                    {/* <span>1,2kb/1,2kb</span> */}
                  </div>
                  <div>
                    <img src={isChecked} alt="Checked" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {popUpFeedBack.loading && (
            <Modal
              onClose={() => setPopUpFeedBack({ ...popUpFeedBack, loading: !popUpFeedBack.loading })}
            >
              <div className={styles.modalContainer}>
                <p>
                  Sua análise está em processamento e pode demorar alguns minutos para ser concluída
                </p>
                <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
              </div>
            </Modal>
         )}

          {popUpFeedBack.error400 && (
            <Modal
              onClose={() => setPopUpFeedBack({ ...popUpFeedBack, loading: false, error400: false })}
            >
              <div className={styles.modalContainer}>
                <p>
                  Documento não encontrado na base de dados! Verifique o documento informado.
                </p>
                <img src={error} alt="warning" />

              </div>
            </Modal>
         )}

        {popUpFeedBack.datesImported && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, datesImported: !popUpFeedBack.datesImported })}
          >
            <div className={styles.modalContainer}>

              <img src={success} alt="warning" />
              <p>
                Dados importados com sucesso!
              </p>
            </div>
          </Modal>
        )}

      {popUpFeedBack.success && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, success: !popUpFeedBack.success })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              {/* <h1>Titulo  do modal</h1> */}
              <img src={success} alt="warning" />

              <p>
                A sua análise foi gerada com sucesso!
              </p>

              <button className={styles.btnSubmit} onClick={() => {
                 window.open(documentUrl)
                 window.location.reload()
                 }}>Fechar</button>
            </div>
          </Modal>
        )}

        {toggleModal && (
          <Modal
            onClose={() => setToggleModal(!toggleModal)}
          >
            <div className={styles.modalContainer}>
              {/* <h1>Titulo  do modal</h1> */}
              <img src={cost.haveCredits ? warning : error} alt="warning" />

              {cost.haveCredits ? (
                <>
                  <p>
                    O custo de análise por CPF/CNPJ deste relatório será de <span>{formatToBRL(cost.unitCost)}</span>. Totalizando um custo total de  <span> {formatToBRL(parseFloat(cost.unitCost) * parseFloat(cost.documents+1))} </span> de seus créditos.
                  </p>

                  {loading ? (
                    <button className={styles.btnSubmit} disabled>Carregando...</button>
                  ) : (
                    <button className={styles.btnSubmit} onClick={() => handleAcceptAnalyst()}>Estou de acordo  e quero gerar análise</button>
                  )}


                </>
              ) : (
                <>
                  <p>O custo para gerar esta análise seria de <span> {formatToBRL(parseFloat(cost.unitCost) * parseFloat(cost.documents+1))}</span>, o <span> que excede os seus créditos atuais</span>.

                    <p>
                      Por favor, entre em contato para inserir novos créditos ou reduza o número de CPF/CNPJs da análise.</p>

                  </p>
                </>
              )}
            </div>
          </Modal>
        )}

          </div>          
        </div>
      </div>
      </ReactCSSTransitionGroup>
    </>
  );
};

export default PortfolioAnalysisSingle;