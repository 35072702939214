import React, { useState } from 'react';
import styles from './style.module.css';
import { useEffect } from 'react';
import VerticalTabs from '../VerticalTabs';
import Tabs from '../../Components/Tabs';
import { Tab } from '../../Components/Tab';
import { getPastDuesByDocument, getTransactionsByDocument } from '../../services/kollectaApi';

const Contracts = ({ data }) => {
  const [ activeTab, setActiveTab ] = useState(0);
  const [ pastDuesByDocument, setPastDuesByDocument ] = useState([]);
  const [ transactionsByDocument, setTransactionsByDocument ] = useState([]);

  const removeSpaces = (string) => {
    return string.replace(/\s+/g, '');
  }

  const getPastDuesByContract = async () => {
    const response = await getPastDuesByDocument(data?.personalNumber, removeSpaces(data?.companyDisplayName));

    if (response.data && response.data?.length > 0) {
      setPastDuesByDocument(response.data);
    }
  }

  const getTransactionsByContract = async () => {
    const response = await getTransactionsByDocument(data?.personalNumber, removeSpaces(data?.companyDisplayName));
  
    if (response.data && response.data?.transactions && response.data?.transactions?.length > 0) {
      const orderedTransactions = [...response.data.transactions].sort((a, b) => a.installment_number - b.installment_number);
      setTransactionsByDocument({ ...response.data, transactions: orderedTransactions });
    }
  }

  useEffect(() => {    
    getPastDuesByContract();
    getTransactionsByContract();
  }, [data]);

  useEffect(() => {
  }, [pastDuesByDocument, data, transactionsByDocument]);

  return (
    <section className={styles.wrapper}>
      <Tabs activeTab={activeTab} onChange={setActiveTab} noPadding>
        <Tab label="Contratos">            
          <VerticalTabs data={pastDuesByDocument} type="contracts" />
        </Tab>
        <Tab label="Acordos"> 
          <VerticalTabs data={transactionsByDocument?.transactions} type="transactions" maxSize={transactionsByDocument?.maxInstallmentNumber} />
        </Tab>
        <Tab label="Condições de Pagamento"> 
          <VerticalTabs data={pastDuesByDocument} type="conditions" />
        </Tab>
      </Tabs>
    </section>
  )
}

export default Contracts;
