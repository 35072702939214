import React, { useState } from 'react';
import styles from './style.module.css';
import { parseCurrency } from '../../../utils/currency';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import LineChartComponent from '../../../Components/Charts/line';
import PieChartComponent from '../../../Components/Charts/pie';
import { TbCurrencyDollar } from 'react-icons/tb';
import Select from '../../../Components/Select';
import { getUniqueCompanyDisplayNames, getPastDuesMeasurements } from '../../../services/kollectaApi';
import { useEffect } from 'react';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import DateFilter from '../../../Components/DateFilter';
import { Button } from '../../../Components';
import Modal from 'react-modal';
import ReportFilterTool from '../../../Components/ReportFilterTool';
import ModalStatus from '../../../Components/ReportFilterTool/ModalStatus';

const Home = ({
  data,
  last30Day,
}) => {
  const [creditors, setCreditors] = useState('');
  const [companyDisplayName, setCompanyDisplayName] = useState('all');
  const [pastDuesMeasurements, setPastDuesMeasurements] = useState();
  const [loading, setLoading] = useState(true);
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null, filter: null });
  const [modal, setModal] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);

  const handleDateRangeChange = (range) => {
    setDateRangeFilter(range);
  };
  const handleSelectChange = (event) => {
    setCompanyDisplayName(event.target.value);
  }

  const getPastDuesMeasurementsData = async () => {
    const data = {
      companyDisplayName,
      startDate: dateRangeFilter.startDate,
      endDate: dateRangeFilter.endDate,
    }
    const pastDues = await getPastDuesMeasurements(data);
    if (pastDues?.data?.data) {
      setPastDuesMeasurements(pastDues.data.data);      
    }
    setLoading(false);
  }

  const getCreditors = async () => {
    const creditors = await getUniqueCompanyDisplayNames();
    if (creditors?.data?.creditors) {
      const options = [
        { value: 'all', label: 'Todos credores' },
        ...creditors.data.creditors.map(item => ({ value: item.value, label: item.label }))
      ];
      setCreditors(options);
    }
  }

  const closeFiltersModal = () => {
    setModal(false);
  }

  const closeStatusModal = () => {
    setModal(false);
    setModalFeedback(false);
  }

  useEffect(() => {
    getCreditors();
  }, []);

  useEffect(() => {
    getPastDuesMeasurementsData();
  }, [companyDisplayName, dateRangeFilter]);

  useEffect(() => {
  }, [creditors, pastDuesMeasurements]);

	return (
    <div>
      <LoadingOverlay isVisible={loading}/>

      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>

      <div className={styles.top}>
        <h3 className={styles.titlePastDueDash}>Indicadores | Operação de cobrança</h3>
      </div>     

      <div className={styles.container}>
        <div className={styles.cardsWrapper}>
          <div className={styles.containerTop}>
            <h3> Dimensão da carteira </h3>
            <div className={styles.containerFilters}>
              {creditors && <Select 
                options={creditors}
                value={companyDisplayName}
                onChange={handleSelectChange}
              />}

              <div className={styles.generalButton}>
                <Button onClick={() => setModal(true)}>
                  Exportar Relatório
                </Button>
              </div>

            </div>
          </div>
          <div className={styles.containerCards}>
            <div className={styles.card}>
              <div>
                <i><TbCurrencyDollar color="#B4B4B4" /></i>
              </div>
              <div>
                <h4 className={styles.subtitlePastDueDash}>Títulos em cobrança</h4>
                <h2 className={styles.amountTotal}>{pastDuesMeasurements && pastDuesMeasurements.count}</h2>
              </div>
            </div>

            <div className={styles.card}>
              <div>
                <i><TbCurrencyDollar  color="#B4B4B4" /></i>
              </div>
              <div>
                <h4 className={styles.subtitlePastDueDash}>Volume total da carteira</h4>
                <h2 className={styles.amountTotal}>{(pastDuesMeasurements && parseCurrency(pastDuesMeasurements.total)) || "R$ -"}</h2>
              </div>
            </div>

            <div className={styles.card}>
              <div>
                <i><TbCurrencyDollar color="#B4B4B4" /></i>
              </div>
              <div>
                <h4 className={styles.subtitlePastDueDash}>Valor recuperado</h4>
                <h2 className={styles.amountTotal}>{pastDuesMeasurements && parseCurrency(pastDuesMeasurements.totalPaid)}</h2>
              </div>
            </div>
          </div>

          <div className={styles.charts}>
            <PieChartComponent />
            <LineChartComponent />
          </div>
        </div>
      </div>

      { !modalFeedback && 
        <Modal
            isOpen={modal}
            onRequestClose={closeFiltersModal}
            style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '8px',
              width: '615px',
            },
          }}
        > 
          <div className={styles.modalContainer}>
            <ReportFilterTool closeModal={closeFiltersModal} setFeedback={setModalFeedback} />
          </div>
        </Modal>  
      }

      <ModalStatus type={modalFeedback} onClose={closeStatusModal}  />

      </ReactCSSTransitionGroup>
    </div>
	)
}

export default Home;
