import React from 'react'
import styles from './style.module.css'
import PortalHeader from '../PortalHeader';
import PortalFooter from '../PortalFooter';

const PortalLayout = ({ children }) => {
  return (   
    <section className={styles.wrapper}>
      <PortalHeader />
        {children}
      <PortalFooter />
    </section>
  )
}

export default PortalLayout;
