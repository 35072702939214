import React from 'react';
import styles from './style.module.css';

const Timeline = ({ events }) => {
  return (
    <div className={styles.timeline}>
      {events.map((event, index) => (
        <div key={index} className={styles.timelineEvent}>
          <div className={styles.date}>{event.date} - {event.operator}</div>
          <div className={styles.description}><b>{event.label}</b>: {event.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;