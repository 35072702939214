import React from 'react';
import { Layout, Title } from '../../../Components';
import styles from './style.module.css';
import Success from '../../../assets/images/success.png';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';

const Feedback = ({
  result,
  settings
}) => {
  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const customerName = pathOr(null, ["customerName"], pastDue)
  const firstName = customerName && customerName.split(" ")[0]; 

  return (
    <Layout>
      <Title size="large">
        {firstName},  tudo certo! O boleto de quitação foi enviado para o seu e-mail.
      </Title>
       <div className={styles.image}>
          <img src={Success} className={styles.logoDashboard} alt="kollecta.io sucesso" />
       </div>
    </Layout>
  )
}

export default Feedback;
