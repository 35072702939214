import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QRCode from 'qrcode.react'

import { Alert, Button, Layout, Title } from '../../../Components'
import styles from './style.module.css'
import check from '../../../assets/images/checkCopy.svg'
import { pathOr } from 'ramda'
import GoBack from '../../../Components/GoBack'
import ReactGA from 'react-ga';
import PortalLayout from '../../../Components/PortalLayout'

const paymentMethods = {
  pix: {
    title: 'Código PIX gerado com sucesso!',
    subtitle: 'Utilize o código para realizar o pagamento',
    clipboard: 'Código copiado com sucesso',
    btnText: 'Copiar código',
  },
  boleto: {
    title: 'Pague abaixo com boleto ',
    subtitle: 'Use a linha digitável abaixo para fazer o pagamento do boleto.',
    clipboard: 'Linha digitável copiada com sucesso',
    btnText: 'Baixar boleto',
    text: 'Confira abaixo as informações',
  },
  installments:  {
    title: 'O boleto da entrada foi gerado!',
    subtitle: 'Use a linha digitável abaixo para fazer o pagamento do boleto.',
    info: 'Utilize o número do código de barras para realizar o pagamento',
    clipboard: 'Código copiado com sucesso',
    btnText: 'Baixar boleto',
    text: 'Pague a entrada para ativar o parcelamento',
  },
}

const Payment = ({
  paymentMethod,
  data,
  installmentDay,
}) => {
  const [copy, setCopy] = useState(false)

  const code = (
    data.pastDueTransactions 
    && data.pastDueTransactions.filter(deal => deal.activated)
  )

  const renderCode = (
    paymentMethod === 'pix' && code.length > 0
      ? pathOr("", [0, "pix_qr_code"], code)
      : pathOr("", [0, "boleto_barcode"], code)
  )

  const boletoUrl = (pathOr("", [0, "boleto_url"], code));  
  
  const handleCopy = () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'COPIAR'
    });
    setCopy(true)
  }

  const directToBoleto = () => {
    window.open(boletoUrl, '_blank');
  }

  useEffect(() => {

  }, [data, copy, renderCode, boletoUrl]);


  return (
    <PortalLayout>
      <div className={styles.offerContainer}>
        <div className={styles.top}>
          <div className={styles.topContent}>
          {installmentDay <=0 && (
           <>
           <h1> Parabéns </h1>
           <p> Seu acordo foi gerado com sucesso! <br />
            {paymentMethod && paymentMethods[paymentMethod]?.text}
           </p>
          </>
        )}


          {installmentDay >0 && (
          <>
        <h1> Parabéns 🎉 </h1>
        <p> Seu acordo foi gerado com sucesso!  <br />
         {paymentMethod && paymentMethods.installments.text}
        </p>
          </>
        )}
          </div>
        </div>

        <div className={styles.bigCard}>

          <div className={styles.topCard}>
            <h2> 
              {paymentMethod && paymentMethods[paymentMethod].title} 
            </h2>
            <h3>
              {paymentMethod && paymentMethods[paymentMethod].subtitle} 
            </h3>

            <p>
              Linha digitável
            </p>

            
            <Alert show={copy}>
              <img src={check} alt="arrow-right" height="20px" />
                <strong>{paymentMethod && paymentMethods[paymentMethod].clipboard}</strong>
            </Alert>

            <span className={styles.copyBarcode}>
              <b>{renderCode}</b>
              <CopyToClipboard text={renderCode}>
                <button onClick={handleCopy} className={styles.copyBarcodeButton}>
                  COPIAR
                </button>
              </CopyToClipboard>
             
            </span>
          </div>
          <div className={styles.paymentCode}>
              {paymentMethod === "boleto" && (
                renderCode
              )}
            {paymentMethod === "pix" && (
              <QRCode value={renderCode} />
            )}
          </div>
          <div className={styles.redirectToBoleto}>;        
            <Button onClick={directToBoleto}>
              {paymentMethod && paymentMethods[paymentMethod].btnText} 
            </Button>
          </div>


        </div>
        
      </div>
    </PortalLayout>
  )
}

export default Payment
