import React, { useEffect } from 'react';
import parseStatus from '../../utils/statusParse';
import eye from '../../assets/images/eye.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.css';

const TableSimple = ({ headers, rows, formatFunction, linkColumnIndex, fieldsToDisplay, dateRangeFilter, columns }) => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(null);

    const handleCellClick = (item) => {
      setSelectedItem(item);
      if (item !== null) {
        navigate('/dashboard/details', { state: { data: item } });
      }
    }

    const extractDateOnly = (date) => {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const filterRowsByDate = (rows) => {
      if (!dateRangeFilter.startDate || !dateRangeFilter.endDate) {
        return rows;
      }
      
      const startDate = extractDateOnly(new Date(dateRangeFilter.startDate));
      const endDate = extractDateOnly(new Date(dateRangeFilter.endDate));
    
      return rows.filter(row => {
        const updatedAtDate = extractDateOnly(new Date(row.updatedAt));
        const schedulingDate = extractDateOnly(new Date(row.scheduling));
    
        return (updatedAtDate >= startDate && updatedAtDate <= endDate) ||
               (schedulingDate >= startDate && schedulingDate <= endDate);
      });
    }

    return (
        <table className={styles.wrapper} style={{ '--columns': `repeat(${columns}, 1fr)` }}>
          <thead>
            <tr>
              {headers.map((header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {filterRowsByDate(rows).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {fieldsToDisplay.map((fieldKey, cellIndex) => {
                  if (headers[cellIndex] === "") {
                    return (
                      <td key={cellIndex}>
                        <img onClick={() => handleCellClick(row)} src={eye} alt="Botão para página de detalhe" />
                      </td>
                    );
                  }
                  
                  if (fieldKey === 'status') {
                    return (
                      <td key={cellIndex}>
                        {parseStatus(row[fieldKey])}
                      </td>
                    );
                  }

                  if (fieldKey === 'statusRow') {
                    return (
                      <td key={cellIndex}>
                        {parseStatus(row[fieldKey])}
                      </td>
                    );
                  }
                  
                  return (
                    <td key={cellIndex}>
                      {fieldKey === formatFunction?.field ? formatFunction.function(row[fieldKey]) : row[fieldKey]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
    );
}

export default TableSimple;
