import React from 'react';
import styles from './style.module.css';

const EffectIcon = () => (
  <svg width="108" height="53" viewBox="0 0 108 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M54 -1.45428e-06C83.8234 -1.50661e-07 108 9.06133e-07 108 9.06133e-07C108 29.2711 83.8234 53 54 53C24.1766 53 1.03722e-06 29.2711 2.3167e-06 -3.8147e-06C2.3167e-06 -3.8147e-06 24.1766 -2.7579e-06 54 -1.45428e-06Z" 
      className={styles.effectFill}
    />
  </svg>
);

export default EffectIcon;