import React, { useEffect, useState, useRef } from 'react';
import styles from './style.module.css';
import Card from '../Card';
import { getByUser, getByUserStatus, getMonthlyGoalsByMonthly } from '../../services/kollectaApi';
import LoadingOverlay from '../LoadingOverlay';
import ProgressBar from "@ramonak/react-progress-bar";
import { parseCurrency } from '../../utils/currency';

const TargetTracker = ({ user }) => {
  const [monthlyGoals, setMonthlyGoals] = useState(null);
  const [pastDues, setPastDues] = useState(0);
  const [userPastDuesPaid, setUserPastDuesPaid] = useState(0);
  const [userPastDuesGenerated, setUserPastDuesGenerated] = useState(0);
  const [userPastDuesPromises, setUserPastDuesPromises] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      if (!user || !user.id) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const date = new Date();
        const currentMonth = date.getMonth();
        const [goals, allPastDues, generated, paids, promises] = await Promise.all([
          getMonthlyGoalsByMonthly(user.id, currentMonth + 1),
          getByUser(),
          getByUserStatus('generated'),
          getByUserStatus('paid'),
          getByUserStatus('promise_to_pay')
        ]);

        if (isMounted) {
          setMonthlyGoals(goals ? goals.data[0] : null);
          setPastDues(allPastDues ? allPastDues.data.length : 0);
          setUserPastDuesGenerated(generated ? generated.data.reduce((acc, prev) => acc + Number(prev.originalAmount), 0) : 0);
          setUserPastDuesPaid(paids ? paids.data.reduce((acc, prev) => acc + Number(prev.originalAmount), 0) : 0);
          setUserPastDuesPromises(promises ? promises.data.reduce((acc, prev) => acc + Number(prev.originalAmount), 0) : 0);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          setLoading(false);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [user]);

  const generatePercentage = (number, target) => {
    const percentage = (number / target) * 100;
    return Math.min(percentage, 100); // Ensure the percentage does not go over 100
  };

  useEffect(() => {
    if (monthlyGoals) {
      const container = document.querySelector('body');
      container.style.setProperty('--userPastDuesPaid', `${userPastDuesPaid ? generatePercentage(userPastDuesPaid, monthlyGoals.paid_agreements) : 0}%`);
      container.style.setProperty('--pastDues', `${pastDues ? generatePercentage(pastDues, monthlyGoals.contacts) : 0}%`);
      container.style.setProperty('--userPastDuesPromises', `${userPastDuesPromises ? generatePercentage(userPastDuesPromises, monthlyGoals.payment_promises) : 0}%`);
      container.style.setProperty('--userPastDuesGenerated', `${userPastDuesGenerated ? generatePercentage(userPastDuesGenerated, monthlyGoals.generated_agreements) : 0}%`);
    }
  }, [monthlyGoals, userPastDuesPaid, pastDues, userPastDuesPromises, userPastDuesGenerated]);

  if (!monthlyGoals) {
    return (
      <section className={styles.wrapper}>
        <h4> Metas/Resultados </h4>
        <Card>
          <LoadingOverlay isVisible={loading} />
          {!loading && (
            <div className={styles.container}>
              <div className={styles.content}>
                Não existe meta cadastrada
              </div>
            </div>
          )}
        </Card>
      </section>
    );
  }

  return (
    <section className={styles.wrapper}>
      <h4> Metas/Resultados </h4>
      <Card padding25={true}>
        <LoadingOverlay isVisible={loading}/>

        <div className={styles.progressBarContainer}>
          <div className={styles.barContainer}>
            <div className={styles.titleContainer}>
              <h5> Acionamentos </h5>
              <span> Sua meta: <b>{monthlyGoals?.contacts }</b> </span>
            </div>

            <div className={styles.progressBarHover}>
              <ProgressBar 
              completed={pastDues ? generatePercentage(pastDues, monthlyGoals.contacts) : 0} 
              bgColor="#219BE4" 
              className={styles.customProgressBar} 
              customLabel={pastDues && pastDues}
              labelAlignment="outside"
              isLabelVisible={true}
              labelClassName={styles.tooltipBlue} 
              />              
            </div>
          </div>

          <div className={styles.barContainer}>
            <div className={styles.titleContainer}>
              <h5> Promessas de pagamento </h5>
              <span> Sua meta: <b>{ monthlyGoals?.payment_promises && parseCurrency(monthlyGoals?.payment_promises)}</b> </span>
            </div>

            <div className={styles.progressBarHover}>
              <ProgressBar 
              completed={userPastDuesPromises ? generatePercentage(userPastDuesPromises, monthlyGoals.payment_promises) : 0}
              bgColor="#635AD9"
              className={styles.customProgressBar} 
              customLabel={userPastDuesPromises ? parseCurrency(userPastDuesPromises) : 0}
              labelAlignment="outside"
              isLabelVisible={true}
              labelClassName={styles.tooltipViolet} 
              />
            </div>
          </div>

          <div className={styles.barContainer}>
            <div className={styles.titleContainer}>
              <h5> Acordos emitidos </h5>
              <span> Sua meta: <b>{ monthlyGoals?.generated_agreements && parseCurrency(monthlyGoals?.generated_agreements) }</b> </span>
            </div>
            <div className={styles.progressBarHover}>
              <ProgressBar 
              completed={(userPastDuesGenerated || userPastDuesPaid) ? generatePercentage((userPastDuesGenerated+userPastDuesPaid), monthlyGoals.generated_agreements) : 0}
              bgColor="#88F4FF"
              className={styles.customProgressBar}
              customLabel={(userPastDuesGenerated || userPastDuesPaid) && parseCurrency(userPastDuesGenerated+userPastDuesPaid)}
              labelAlignment="outside"
              isLabelVisible={true}
              labelClassName={styles.tooltipLightBlue}
              />
            </div>
          </div>

          <div className={styles.barContainer}>
            <div className={styles.titleContainer}>
              <h5> Acordos pagos </h5>
              <span> Sua meta: <b>{ monthlyGoals?.paid_agreements && parseCurrency(monthlyGoals?.paid_agreements) }</b> </span>
            </div>
            <div className={`${styles.progressBarHover}`}>
              <ProgressBar 
              completed={userPastDuesPaid ? generatePercentage(userPastDuesPaid, monthlyGoals.paid_agreements) : 0}
              bgColor="#12D849" 
              className={styles.customProgressBar}
              customLabel={userPastDuesPaid && parseCurrency(userPastDuesPaid)}
              labelAlignment="outside"
              isLabelVisible={true}
              labelClassName={styles.tooltipGreen}
               />
            </div>
          </div>
        </div>
      </Card>
    </section>
  )
}

export default TargetTracker;
