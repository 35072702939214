import React from 'react';
import IconArrowUp from '../../../../assets/logos/arrowUp';
import IconArrowDown from '../../../../assets/logos/arrowDown';
import IconArrow from '../../../../assets/logos/arrow';
import styles from './style.module.css';
import { useEffect } from 'react';

const TeamIcon = ({ value }) => {
  let icone;
  let classeIcone;

  if (value > 0) {
    icone = <IconArrowUp width={8} height={8} />;
    classeIcone = styles.arrowUp;
  } else if (value < 0) {
    icone = <IconArrowDown width={8} height={8} />;
    classeIcone = styles.arrowDown;
    value = Math.abs(value);
  } else {
    icone = <IconArrow width={8} height={8} />;
    classeIcone = styles.arrowStraight;
  }

  const classesDiv = `${styles.wrapper} ${classeIcone}`;

  useEffect(() => {
  }, [value]);

  return (
    <div className={classesDiv}>
      <span>{icone}</span> {value}%
    </div>
  );
}

export default TeamIcon;
