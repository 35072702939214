import React, { useState } from 'react'

import { RadioButtonOffer, Button, Layout, Title } from '../../../Components'
import styles from './style.module.css'
import GoBack from '../../../Components/GoBack';

const Payment = ({
  gotToPaymentMethod,
  setDay,
}) => {

  const [isChecked] = useState([]);

  const dates = [
    { description: 'Todo dia 1º de cada mês', value: 1 },
    { description: 'Todo dia 5º de cada mês', value: 5 },
    { description: 'Todo dia 10º de cada mês', value: 10 },
  ]

  const handleChange = ({ target }) => {
    const { value } = target;
    isChecked[0] = value;
    setDay(value)
  }
  
  return (
    <Layout logo={false}>
      <div className={styles.paymentContainer}>
        <div className={styles.top}>
          <div className={styles.goBackButton}>
            <GoBack />
          </div>
          <div className={styles.topContent}>
            <Title size="superLarge" color='white'>
              Escolha uma <br />
              boa data...
            </Title>
          </div>

          <div className={styles.customImage}>

          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.text}>
            <Title size="large" color="primary">
              Qual é a melhor data para pagar as suas parcelas:
            </Title>
          </div>

          {dates.map(({ description, value}) => (
            <RadioButtonOffer name="payment-due-at" key={description} value={value} onChange={handleChange} checked={+isChecked[0] === +value && true}>
              <Title color="primary" align="left">
                {description}
              </Title>
            </RadioButtonOffer>
          ))}
          <div style={{ margin: '50px auto' }}>
            <Button onClick={gotToPaymentMethod} color="dark">
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Payment
