import { createSlice } from '@reduxjs/toolkit'

export const pastdueTransaction = createSlice({
  name: 'pastdueTransaction',
  initialState: {
    pastdueTransaction: {}
  },
  reducers: {
    setPastdueTransaction: (state, action) => {
      state.pastdueTransaction = action.payload
    },
    reset: (state) => {
      state.pastdueTransaction = {}
    },
  },
})

export const { 
  setPastdueTransaction, 
  reset,
} = pastdueTransaction.actions

export default pastdueTransaction.reducer
