import React from 'react';
import styles from './style.module.css';

const HeaderInternal = ({ title, subtitle}) => {
  return (
    <section className={styles.wrapper}>
      <h3>{title}</h3>
      <h4>{subtitle}</h4>
    </section>
  );
};

export default HeaderInternal;