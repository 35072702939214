import React from 'react';

function IconArrow(props) {
  const {
    fill,
    width,
    height,
  } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.59643 4.91991C7.78858 4.72468 7.78354 4.40814 7.58517 4.2129L4.35248 1.03132C4.1541 0.836084 3.83752 0.836084 3.64537 1.03132C3.45322 1.22656 3.45826 1.5431 3.65664 1.73834L6.53014 4.56641L3.74679 7.39447C3.55464 7.58971 3.55968 7.90625 3.75805 8.10149C3.95643 8.29673 4.27301 8.29673 4.46516 8.10149L7.59643 4.91991ZM0.307529 5.06634H7.24521L7.22928 4.06647H0.291592L0.307529 5.06634Z" fill="#533B17"/>
    </svg>       
    
  );
}

export default IconArrow;
