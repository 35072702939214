import React, { useEffect, useState } from 'react'
import LoginContainer from '../../../Container/Dashboard/Login'
import { useLocation, useNavigate } from "react-router-dom"

import { useDispatch } from 'react-redux'
import { pathOr } from 'ramda'
import { setCompanySettings } from '../../../Redux/Reducers/CompanySettings'

import { auth, getCompanySettings } from '../../../services/kollectaApi'
import LogRocket from 'logrocket';

import ReactGA from 'react-ga';

const Login = () => {
  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const [loggedIn, setLoggedIn] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  
  const dispatch = useDispatch()
  
  let navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    getCompanySettingsScreeng()
  }, [location.pathname]);

  useEffect(() => {
    const currentHostname = window.location.hostname;
    const user = localStorage.getItem('user');
    let userType = 'operator';
    if (user) {
      const userParse = JSON.parse(user);
      userType = userParse.userType;
    }
    if ((loggedIn || localStorage.getItem('token')) && (currentHostname.includes('kollecta.io') || (currentHostname.includes('local')))) {
      if (userType === 'operator') {
        return navigate("/dashboard/home-operator");
      }
      return navigate("/dashboard/home");
    }
  }, [loggedIn, navigate])

  const handleChange = ({ target }) => {
    const { name, value } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Acessar painel'
    });
    try {
      setError(null)
      const response = await auth(form)

      const user = JSON.stringify(response.data.user)

      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user.name', response.data.user.name)
      localStorage.setItem('user', user)
      setLoggedIn(response.data)
    } catch (error) {
      setError("Usuário/senha inválidos")
      console.log(error)
    }

  LogRocket.identify(form.email);

  }

  const getCompanySettingsScreeng = async () => {
      const locationPath = pathOr(
        'https://portal.kollecta.io',
        ['location', 'href'],
        window,
      );
      
      try {
        const { host, hostname } = new URL(locationPath);
        

        if (hostname.startsWith('www.') || hostname.startsWith('portal.')) {
          window.location.href = "https://kollecta.app";

       } else if (host.includes('kollecta.app')) {
          navigate("/offers/customer");
        }
        
        const { data } = await getCompanySettings(host);

        if (data && data.siteUrl) {
          if (data) {
            document.documentElement.style.setProperty('--portal-header-primary', data.primaryColor);
            document.documentElement.style.setProperty('--portal-header-secondary', data.secondaryColor);
            document.documentElement.style.setProperty('--portal-logo', data.logo);

            localStorage.setItem('primaryColor', data.primaryColor);
            localStorage.setItem('secondaryColor', data.secondaryColor);
            localStorage.setItem('logo', data.logo);
            
            dispatch(setCompanySettings(data));
            navigate(`/offers/customer?company=${data?.id}`);
          }
        }
        setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <LoginContainer
      form={form}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      loading={loading}
    />
  )
}

export default Login
