import React from 'react';
import styles from './style.module.css';

const FooterBigEllipse = () => (
  <svg width="123" height="62" viewBox="0 0 123 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M61.4966 61.0666C27.5329 61.0666 -2.55575e-05 61.0665 -2.55575e-05 61.0665C-2.40833e-05 27.3404 27.5329 -4.1727e-06 61.4966 -2.6881e-06C95.4602 -1.2035e-06 122.993 27.3404 122.993 61.0666C122.993 61.0666 95.4602 61.0666 61.4966 61.0666Z"  className={styles.effectFill}/>
  </svg>
  
);

export default FooterBigEllipse;