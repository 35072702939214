import React, { useState } from "react";
import { useEffect } from "react";
import styles from './style.module.css';
import { getFiltersExcel } from '../../services/kollectaApi';
import { IoIosClose } from "react-icons/io";
import CustomRangePicker from "../CustomRangerPicker";
import { format } from 'date-fns';

const ReportFilterTool = ({ closeModal, setFeedback }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const statusSettings = {
    viewed: {
      label: "Acordo consultado",
    }, 
    generated: {
      label: "Acordo emitido",
    },
    pending: {
      label: "Acordo em progresso",
    },
    agreement_broken: {
      label: "Acordo quebrado",
    },
    paid: {
      label: "Acordo quitado",
    },
    scheduled_contact: {
      label: "Contato agendado",
    },
    promise_to_pay: {
      label: "Promessa de pagamento",
    },
    indirect_payment: {
      label: "Pagamento indireto",
    },
  }

  const statusSettingsEngagement = {
    contacted: {
      label: "Contato realizado",
    },
    cpc: {
      label: "CPC",
    },
    cpe: {
      label: "CPE",
    },
    deny_pending: {
      label: "Não reconhece pendência",
    },
    waiting_payment: {
      label: "Pendente",
    },
    confirm_pending: {
      label: "Reconhece pendência",
    },
    without_terms_payment: {
      label: "Sem Cond. Pgto",
    },
    not_return: {
      label: "Sem retorno",
    },
    suspected_fraud: {
      label: "Suspeita de fraude",
    },
  }

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const [selectedStatuses, setSelectedStatuses] = useState(
    Object.keys(statusSettings).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  const [selectedEngagementStatuses, setSelectedEngagementStatuses] = useState(
    Object.keys(statusSettingsEngagement).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  const handleStatusChange = (statusKey) => {
    setSelectedStatuses(prevSelectedStatuses => ({
      ...prevSelectedStatuses,
      [statusKey]: !prevSelectedStatuses[statusKey]
    }));
  };

  const handleEngagementStatusChange = (statusKey) => {
    setSelectedEngagementStatuses(prevStatuses => ({
      ...prevStatuses,
      [statusKey]: !prevStatuses[statusKey]
    }));
  };

  const handleExport = async () => {
    setFeedback('isLoading');

    const selectedStatusKeys = Object.keys(selectedStatuses).filter(key => selectedStatuses[key]);
    const selectedEngagementKeys = Object.keys(selectedEngagementStatuses).filter(key => selectedEngagementStatuses[key]);
  
    const allSelectedStatuses = [...selectedStatusKeys, ...selectedEngagementKeys];
    const formattedStartDate = startDate ? format(startDate, "yyyy-MM-dd") : null;
    const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : null;
  
    const filters = {
      "statuses": allSelectedStatuses,
      "startDate": formattedStartDate,
      "endDate": formattedEndDate
    };

 
    try {
      const response = await getFiltersExcel(filters);
      if (response) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const downloadUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'relatorio.xlsx';
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(downloadUrl);
        a.remove();
        setFeedback('isSuccess');
      }
    } catch (error) {
      setFeedback('isError');
      console.error("There was an error exporting the report:", error);
    }
  };

  useEffect(() => {
  }, [selectedStatuses, selectedEngagementStatuses]);


  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
       <h2>Escolha as informações do seu relatório</h2>
       <button onClick={() => closeModal(false)} className={styles.close}><IoIosClose /></button>
      </div>
      
      <div className={styles.container}>
          <div className={styles.subContainer}>
            <h3>Segmentação de acordos</h3>
            <div className={styles.radiosContainer}>
              {Object.entries(statusSettings).map(([key, { label }]) => (
              <div key={key} className={styles.radioItem}>
                <input 
                  type="checkbox" 
                  id={key} 
                  name="status"
                  className={styles.customCheckbox} // Adicione esta linha
                  checked={selectedStatuses[key]}
                  onChange={() => handleStatusChange(key)}
                />
                <div className={styles.radioTexts}>
                  <label htmlFor={key}>{label}</label>
                </div>
              </div>
             ))}
            </div>
          </div>

          <div className={styles.subContainer}>
            <h3>Indicadores de engajamento</h3>
            <div className={styles.radiosContainer}>
              {Object.entries(statusSettingsEngagement).map(([key, { label }]) => (
              <div key={key} className={styles.radioItem}>
                <input 
                  type="checkbox" 
                  id={`engagement-${key}`}
                  name="engagementStatus"
                  className={styles.customCheckbox} // Adicione esta linha
                  checked={selectedEngagementStatuses[key]}
                  onChange={() => handleEngagementStatusChange(key)}
                />
                <div className={styles.radioTexts}>
                  <label htmlFor={`engagement-${key}`}>{label}</label>
                </div>
              </div>
            ))}
            </div>
          </div>
      </div>

      <div className={styles.calendar}>
        <CustomRangePicker onDateChange={handleDateChange} />
      </div>

      <button onClick={handleExport} className={styles.generalButton}>Exportar Relatório</button>

    </section>
  );
};

export default ReportFilterTool;
