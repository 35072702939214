import React, { useState } from 'react';
import styles from './style.module.css';

const TextareaComponent = ({ onValueChange }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  }

  return (
    <div className={styles.wrapper}>
      <textarea 
        value={value} 
        onChange={handleChange}
        placeholder="Adicione um comentário..."
        rows="5"
      />
    </div>
  );
}

export default TextareaComponent;
