import React, { useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { usersStats } from '../../../services/kollectaApi';
import { useEffect } from 'react';
import HeaderInternal from '../../../Components/HeaderInternal';
import Card from '../../../Components/Card';
import Tabs from '../../../Components/Tabs';
import { Tab } from '../../../Components/Tab';
import TeamIcon from './Arrow';
import TeamTable from './Table';

const TeamContainer = () => {
  const [user, setUser] = useState({});
  const [ activeTab, setActiveTab ] = useState(0);
  const [ usersData, setUsersData ] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    return `${d.getFullYear()}-${month}`;
  };

  const getCurrentYearMonth = () => {
    const now = new Date();
    return formatDate(now);
  };

  const getUsersData = async () => {
    const startDate = dateRange.startDate ? formatDate(dateRange.startDate) : getCurrentYearMonth();
    const endDate = dateRange.endDate ? formatDate(dateRange.endDate) : getCurrentYearMonth();
    
    const response = await usersStats(startDate, endDate);
    if (response?.status === 200) {
      setUsersData(response?.data || []);
      return response?.data || null;
    }
    return null;
  }


  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    setUser(userParse);
    getUsersData();
  }, [dateRange]);

  useEffect(() => {
  }, [usersData, dateRange]);


	return (
    <div className={styles.teamContainer}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>

      <div className={styles.top}>
        <HeaderInternal title={user ? user.name : ''} subtitle={user ? user.email : ''} /> 
      </div>  
      {
        usersData?.length === 0 ?
        <h1> Nenhum operador cadastrado </h1>
        :
        <div className={styles.container}>
          <div className={styles.containerTop}>
            <h3> Destaque do mês </h3>
            <div className={styles.items}>
              {usersData && usersData.slice(0, 3).map((userDataItem) => (
                <Card>
                  <div className={styles.cardContainer}>
                    <div className={styles.cardTop}>
                      <h3> {userDataItem?.name} </h3>
                    </div>
                    <div className={styles.cardContent}>
                      <TeamIcon value={userDataItem?.realizationPercentage} />
                      <span> Pagos: {userDataItem?.totalPaidAmount} </span>
                    </div>
                    <br />
                  </div>
                </Card>
              ))}
            </div>
          </div>

          <div className={styles.containerContent}>
          <Tabs activeTab={activeTab} onChange={setActiveTab} noPadding styleType="team" onDateRangeChange={handleDateRangeChange} minHeight={500}>
            <Tab label="Performance por colaborador">  
              <TeamTable data={usersData} />          
            </Tab>
          </Tabs>
          </div>
        </div>
      }      
      </ReactCSSTransitionGroup>
    </div>
	)
}

export default TeamContainer;
