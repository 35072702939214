import React from 'react';
import { FiCheck } from 'react-icons/fi';
import styles from './style.module.css';

const CustomRadioButton = ({ label, active, onClickFunction }) => {

  const handleCheck = () => {
    onClickFunction(label);
  };

  return (
    <div className={`${styles.container} ${active ? styles.containerChecked : ''}`} onClick={handleCheck}>
      <div className={`${styles.radio} ${active ? styles.radioChecked : styles.radioUnchecked}`}>
        {active && <FiCheck />}
      </div>
      <span className={active ? styles.labelChecked : styles.labelUnchecked}>{label}</span>
    </div>
  );
};

export default CustomRadioButton;