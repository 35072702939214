import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '../../Card';
import styles from './style.module.css';
import { formatToBRL } from 'brazilian-values';
import { getLineChartData } from '../../../services/kollectaApi';
import DateRangeSelector from '../DateRangeSelector';

function CustomizedLegend({ payload }) {
  return (
    <ul style={{ display: 'flex', justifyContent: 'flex-end', padding: 0, listStyleType: 'none' }} className={styles.customLegend}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className={styles.legend}>
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: 3,
              backgroundColor: entry.color,
            }}
          />
          <span style={{ marginLeft: 5 }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
}

function CustomTooltip({ payload, active, currentLine }) {
  if (active && payload && payload.length) {
    const currentItem = payload.find((item) => item.name === currentLine);
    if (currentItem) {
      return (
        <div className={styles.tooltip}>
          <p className={styles.intro}>{`${formatToBRL(currentItem.value / 100)}`}</p>
        </div>
      );
    }
  }
  return null;
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload, isXAxis } = props;
  return (
    <g transform={`translate(${x},${y - 12})`}>
      <text x={0} y={0} dy={isXAxis ? 76 : 16} textAnchor="end" fill="#666" className={styles.tickText}>
        {isXAxis ? payload.value : formatToBRL(payload.value  / 100)}
      </text>
    </g>
  );
};

const LineChartComponent = () => {
  const [currentLine, setCurrentLine] = useState(null);
  const [renderChart, setRenderChart] = useState(false);
  const [dataChart, setDataChart] = useState();
  const [startDate, setStartDate] = useState(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const timer = setTimeout(() => setRenderChart(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const getData = async () => {
    try {
      const data = { startDate, endDate };
      const response = await getLineChartData(data);
      setDataChart(response.data.data);
    } catch (error) {
      console.error('Failed to fetch pie chart data:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const hasNoActivity = () => {
    if (!dataChart) return false;
    let sum = 0;
    const statusKeys = ['Promessa de pagamento', 'Pagos', 'Emitidos', 'Cancelados'];
    dataChart.forEach(dayData => {
      statusKeys.forEach(key => {
        sum += dayData[key];
      });
    });
    return sum === 0;
  };

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#d84a52'];
  const statusKeys = ['Promessa de pagamento', 'Pagos', 'Emitidos', 'Cancelados'];

  const handleMouseMove = (dataKey) => setCurrentLine(dataKey);

  const handleDateChange = async (dates) => {
    const { start, end } = dates;
    setStartDate(start);
    setEndDate(end);
    await getData();
  };

  return (
    <Card>
      <section className={styles.wrapper}>
        <div className={styles.topContent}>
          <h1> Indicadores de recuperação </h1>
          <div> 
            <DateRangeSelector 
              onDateChange={handleDateChange} 
              startDate={startDate}
              endDate={endDate}
            /> 
          </div>
        </div>
        {hasNoActivity() ? (
          <p>Nenhum dado disponível para o período especificado. Altere o filtro de datas.</p>
        ) : (
          renderChart && dataChart && (
            <ResponsiveContainer width="100%" height={500}>
              <LineChart data={dataChart} margin={{ top: 5, right: 20, bottom: 75, left: 20 }}>
                <CartesianGrid vertical={false} />
                <YAxis axisLine={false} tickLine={false} tick={<CustomizedAxisTick />} />
                <Tooltip content={<CustomTooltip currentLine={currentLine} />} />
                <Legend content={<CustomizedLegend />} align="right" verticalAlign="top" wrapperStyle={{ paddingTop: '10px', paddingRight: '10px', paddingBottom: '30px' }} />
                {statusKeys.map((key, index) => (
                  <Line key={key} type="monotone" dataKey={key} stroke={colors[index]} name={key} strokeWidth={3} onMouseMove={() => handleMouseMove(statusKeys[index])} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )
        )}
      </section>
    </Card>
  );
};

export default LineChartComponent;
