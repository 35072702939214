import { configureStore } from '@reduxjs/toolkit'
import pastDueReducer from './Redux/Reducers/PastDue'
import pastdueTransactionReducer from './Redux/Reducers/PastDueTransaction'
import companyReducer from './Redux/Reducers/CompanySettings'

export default configureStore({
  reducer: {
    pastDue: pastDueReducer,
    pastdueTransaction: pastdueTransactionReducer,
    companySettings: companyReducer,
  },
})
