import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import styles from './style.module.css';
import CustomDateHeader from '../CustomDateHeader';
import { format } from 'date-fns';
import CalendarIcon from './icons/calendar.png';
import ArrowIcon from './icons/arrow.png';

function CustomRangePicker({ onDateChange, minDateEnabled }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);


  const handleChange = (dates) => {
    const [start, end] = dates;
    setDateRange([start, end]);
    onDateChange({ start, end });
    if (start && end) {
      setIsCalendarOpen(false);
    }
  };

  const toggleCalendarVisibility = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy", { locale: ptBR });
  };

  const dateRangeString = startDate && endDate 
  ? `${formatDate(startDate)} - ${formatDate(endDate)}`
  : `${formatDate(new Date())}`;

  return (
    <div className="custom-ranger">
      <div className={styles.dateRangeDisplay}>
        <h3>Selecione o período</h3>
        <button className="calendar-button" onClick={toggleCalendarVisibility}>
          <div className="calendar-button-texts">
          <img src={CalendarIcon} alt="button calendar" />
          {dateRangeString}
          </div>
         <img src={ArrowIcon} alt="button arrow"/>
        </button>
      </div>
      {isCalendarOpen && ( 
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          className={styles.datePicker}
          popperClassName={styles.popper}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={(props) => <CustomDateHeader {...props} />}
          inline
          weekStartsOn={0}
        />
      )}
    </div>
  );
}

export default CustomRangePicker;
