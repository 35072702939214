import { Button, InputEmail, Loading, Title } from '../../../Components';
import styles from './style.module.css';
import { pathOr } from 'ramda'
import Arrow from '../../../assets/images/arrow.svg';
import { useSelector } from 'react-redux';

const Email = ({
  handleChange,
  personalEmail,
  settings,
  loading,
  sendEmail,
}) => {

  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const customerName = pathOr(null, ["customerName"], pastDue);
  const firstName = customerName && customerName.split(" ")[0];   

  return (     
      <Loading show={false}>
      <div className={styles.homeContainer}>
        <div className={styles.homeSize}>
          <div className={styles.layoutHeader}>
            <img 
              className={styles.layoutLogoMobile} 
              src={settings.logo} 
              alt={settings.siteUrl} 
            />
          </div>
          <Title size="large">
            {firstName},  prefere receber o seu boleto em qual e-mail?
          </Title>
          <div style={{ margin: '50px 0' }}>
          <InputEmail 
              name="personalEmail" 
              value={personalEmail}
              placeholder="Digite o seu e-mail"
              onChange={handleChange}
            />
          </div>
          <div style={{ margin: '50px 0' }}>
            <Button onClick={sendEmail}>
              Enviar boleto
              <img 
                style={{margin: '0 0 0 10px'}}
                src={Arrow}
                alt="arrow-right"
                height="10px"
              />
            </Button>
          </div>
        </div>
      </div>
    </Loading>
  )
}

export default Email;
