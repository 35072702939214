import React from 'react'
import PastDueContainer from '../../../Container/Dashboard/PastDue'

const PastDue = () => {
	return (
		<PastDueContainer />
	)
}

export default PastDue
