import React from 'react';
import styles from './style.module.css';

const FooterSmallEllipse = () => (
  <svg width="45" height="22" viewBox="0 0 45 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 22C10.0736 22 -9.61651e-07 22 -9.61651e-07 22C-4.30546e-07 9.84973 10.0736 -1.52668e-06 22.5 -9.83506e-07C34.9264 -4.40331e-07 45 9.84974 45 22C45 22 34.9264 22 22.5 22Z" className={styles.effectFill}
    />
  </svg>  
);

export default FooterSmallEllipse;