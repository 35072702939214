import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import { getOperators, createQueue } from '../../services/kollectaApi';
import  handImage from './assets/hand.svg';
import  contractImage from './assets/contract.svg';
import  success from './assets/success.svg';
import { useRef } from 'react';

const CreateQueue = ({ data, user, totalRecords, totalAmount }) => {
  const [operators, setOperators] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modalContent, setModalContent] = useState('initial');

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectAllChange = (isChecked) => {
    setSelectAll(isChecked);
    const newSelectedOperators = {};
    operators.forEach(operator => {
      newSelectedOperators[operator.operatorId] = isChecked;
    });
    setSelectedOperators(newSelectedOperators);
  };

  const getOperatorsData = async () => {
    try {
      const response = await getOperators();
      if (response.data && response.data.length > 0) {
        const initialOperators = response.data.reduce((acc, operator) => {
          acc[operator.operatorId] = false;
          return acc;
        }, {});
        setOperators(response.data);
        setSelectedOperators(initialOperators);

      }
    } catch (error) {
      console.error('Erro ao obter operadores:', error);
    }
  }

  const handleSubmit = async () => {
    const operatorIds = Object.entries(selectedOperators)
                              .filter(([_, isSelected]) => isSelected)
                              .map(([operatorId, _]) => operatorId);

    const pastDueIds = data.map(pastDue => pastDue.id);

    const queueData = {
      operatorIds,
      pastDueIds,
      userId: user.id,
      status: "new"
    };

    try {
      const response = await createQueue(queueData);      
      setModalContent('success');
    } catch (error) {
      console.error('Error creating queue:', error);      
      setModalContent('error');
    }
  }

  const handleCheckboxChange = (operatorId) => {
    setSelectedOperators(prevSelectedOperators => {
      const newOperators = {
        ...prevSelectedOperators,
        [operatorId]: !prevSelectedOperators[operatorId]
      };

      const allChecked = Object.values(newOperators).every(isSelected => isSelected);
      setSelectAll(allChecked);
      return newOperators;
    });
  };

  useEffect(() => {    
    getOperatorsData();
  }, [data]);

  useEffect(() => {
  }, [selectedOperators, user]);

  return (
    <section className={styles.wrapper}>
       {modalContent === 'initial' && (
      <><h1>Distribuição dos contratos</h1><div className={styles.top}>
          <div className={styles.dropdownContainer} ref={dropdownRef}>
            <h5>Operador</h5>
            <div
              className={`${styles.dropdownButton} ${isDropdownOpen ? styles.active : ''}`}
              onClick={handleDropdownClick}
            >
              <span></span>
              <span className={`${isDropdownOpen ? styles.rotate : ''}`}>▼</span>
            </div>
            <div className={`${styles.checkboxList} ${isDropdownOpen ? styles.open : ''}`}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="checkbox-select-all"
                  checked={selectAll}
                  onChange={(e) => handleSelectAllChange(e.target.checked)} />
                <label htmlFor="checkbox-select-all">Selecionar todos os operadores</label>
              </div>
              {operators.map((operator) => (
                <div key={operator.operatorId} className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id={`checkbox-${operator.operatorId}`}
                    checked={selectedOperators[operator.operatorId]}
                    onChange={() => handleCheckboxChange(operator.operatorId)} />
                  <label htmlFor={`checkbox-${operator.operatorId}`}>{operator.name}</label>
                </div>
              ))}
            </div>
          </div>
        </div><div className={styles.bottom}>
            <div className={styles.bottomTop}>
              <h3> Informações de contratos </h3>
            </div>

            <div className={styles.bottomContent}>
              <div className={styles.cardItem}>
                <div className={styles.cardImage}>
                  <img src={handImage} alt="Kollecta" />
                </div>
                <div className={styles.cardContent}>
                  <span> Valor a cobrar: </span>
                  <h4> {totalAmount} </h4>
                </div>
              </div>

              <div className={styles.cardItem}>
                <div className={styles.cardImage}>
                  <img src={contractImage} alt="Kollecta" />
                </div>
                <div className={styles.cardContent}>
                  <span> Número de contratos: </span>
                  <h4> {totalRecords} </h4>
                </div>
              </div>
            </div>
          </div><button onClick={handleSubmit} className={styles.generalButton}>Distribuir</button></>
     )}

    {modalContent === 'success' && (
        <div className={styles.modalSuccessContent}>
          <div className={styles.modalSuccessHeader}>
            <img src={success} alt="Success" />
          </div>
          <div className={styles.modalSuccessBody}>
            <p>A lista foi distribuída com sucesso!</p>
          </div>
        </div>
      )}

    {modalContent === 'error' && (
        <div className={styles.modalSuccessContent}>
          <div className={styles.modalSuccessHeader}>
          </div>
          <div className={styles.modalSuccessBody}>
            <p>Erro ao criar fila, tente novamente</p>
          </div>
        </div>
      )}
    </section>
  );
}

export default CreateQueue;
