import moment from 'moment';

const formatDate = (date) => {
  const formattedDate = moment(date).format('DD/MM/YYYY - HH[h]mm');
  return formattedDate;
};

export {
  formatDate
}
