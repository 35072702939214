

import styles from './style.module.css'
import exampleFile from '../PastDue/example.xlsx'
import isChecked from '../../../assets/images/isChecked.png'
import warning from '../../../assets/images/warning.svg'
import error from '../../../assets/images/error.svg'
import success from '../../../assets/images/success.svg'
import ReactLoading from 'react-loading';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import LogRocket from 'logrocket';

import { UploadFile } from '../../../Components'
import { useCallback, useEffect, useState } from 'react'
import { getCurrencyValue, getFilters, getWallet, naoSeiDownloadWallet, postCreditsUpdate, postWallets, postWalletsConfirm } from '../../../services/kollectaApi'

import { formatToBRL } from 'brazilian-values'
import Modal from '../../../Components/Modal'

const PortfolioAnalysis = () => {
  const dataForm = new FormData()
  const user = JSON.parse(localStorage.getItem('user'))
  const [credit, setCredit] = useState('0')
  const [loading, setIsLoading] = useState(false)
  const [historyAnalysts, setHistoryAnalysts] = useState([])
  const [filtersSelected, setFiltersSelected] = useState([])
  const [filtersToShow, setfiltersToShow] = useState([])
  const [toggleModal, setToggleModal] = useState(false)
  const [dataFormAfterUse, setDataFormAfterUse] = useState()
  const [popUpFeedBack, setPopUpFeedBack] = useState({
    loading: false,
    datesImported: false,
    success: false,
    apiError: false,
    downloadError: false,
    typeError: false,
  })
  const [cost, setCost] = useState({
    documents: 0,
    haveCredits: false,
    unitCost: 0
  })

  const handleAddFilter = (id) => {
    if (!id) return

    filtersToShow.forEach(item => {
      if (item.id === id) {
        setFiltersSelected([...filtersSelected, item])
        return
      }
      return item
    })

  }

  const handleRemoveOption = useCallback((id) => {
    filtersSelected.filter(item => item.id === id)
    setFiltersSelected(filtersSelected.filter(item => item.id !== id))
  }, [filtersSelected])



  const handleGenerateAnalysis = useCallback(async () => {
    if (!dataFormAfterUse || filtersSelected.length < 1) return alert('Antes de efetuar uma análise, é necessário realizar a importação do arquivo de CPFs/CNPJs')
    const array = filtersSelected.map(item => item.dataset)
    const string = array.join(",")

    setIsLoading(true)

    try {
      const response = await postWallets(user.companyId, string, dataFormAfterUse)
      setCost(response.data)
      setToggleModal(!toggleModal)
    
      if (!response.data.haveCredits) {
        setfiltersToShow([])
        setDataFormAfterUse('')
      }
      LogRocket.log('Análise em lote - handleGenerateAnalysis succefully', { company: user.companyId });
    } catch (error) {
      LogRocket.error('Error - Análise em lote - handleGenerateAnalysis', { error: JSON.stringify(error) });
    }
    setIsLoading(false)
  }, [dataFormAfterUse, filtersSelected, popUpFeedBack, user.companyId, toggleModal])

  const handleAcceptAnalyst = useCallback(async () => {
    setIsLoading(true)
    setPopUpFeedBack({ ...popUpFeedBack, loading: true })
    setToggleModal(false)

    const array = filtersSelected.map(item => item.dataset)
    const string = array.join(",")

    const data = {
      value: parseFloat(credit.value) - (parseFloat(cost.unitCost) * parseFloat(cost.documents+1)),
      companyId: user.companyId
    }

    try {
      const response = await postWalletsConfirm(user.id, user.companyId, string, dataFormAfterUse)
      if (response.data.error && response.data.error === 'typeFile') {
        setToggleModal(false)
        setIsLoading(false)
        setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, typeError: true })
        return;
      }
      
      if (!response.data.result) {
        setToggleModal(false)
        setIsLoading(false)
        setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, apiError: true })
        return;
      }
      
      try {
        await postCreditsUpdate(data);
        LogRocket.log('Análise em lote - postCreditsUpdate succefully', { company: user.companyId });
      } catch (error) {
        console.log('Error postCreditsUpdate: ', error);
        LogRocket.error('Error - Análise em lote - postCreditsUpdate', { error: JSON.stringify(error) });

      }

      try {
        const handleDownload = await handlePostDownloadFile(response.data.fileId);
        if (!handleDownload.data.result) {
          setToggleModal(false)
          setIsLoading(false)
          setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, apiError: false, downloadError: true })
          LogRocket.error('Error - Análise em lote - Erro no download do arquivo', { error: JSON.stringify(error) });
  
          return;
        }

      } catch(error) {
        console.log('Erro no download', error);
      }

      setToggleModal(false)
      setIsLoading(false)
      setPopUpFeedBack({ ...popUpFeedBack, success: true, loading: false })
      LogRocket.log('Análise em lote - Sucesso no download do arquivo', { company: user.companyId });



    } catch (error) {
      alert('Ocorreu um erro ao gerar a análise')
      console.log('Error - Análise em lote - Ocorreu um erro ao gerar a análise', { error: error } );
      LogRocket.error('Error - Análise em lote - Ocorreu um erro ao gerar a análise', { error: JSON.stringify(error) });
      setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false })
      setIsLoading(false)
    }
    setIsLoading(false)
  }, [popUpFeedBack, filtersSelected, credit.value, cost.unitCost, cost.documents+1, user.companyId, dataFormAfterUse])



  const handlePostDownloadFile = useCallback(async (id) => {
    try {
      const response = await naoSeiDownloadWallet(id)
      return response;
    } catch (error) {
      LogRocket.error('Error - Análise em lote - Ocorreu um erro ao gerar o download do arquivo', { error: JSON.stringify(error) });
      
    }
  }, [])




  const handleGetCurrency = useCallback(async () => {
    try {
      const response = await getCurrencyValue(user.companyId)
      setCredit(response.data)
    } catch(error) {
      alert('Erro ao buscar o credito')
      LogRocket.error('Error - Análise em lote - Ocorreu um erro ao buscar o credito', { error: JSON.stringify(error) });

    }
  }, [user.companyId, setCredit])


  const handleGetFilters = useCallback(async () => {

    try {
      const response = await getFilters()
      var reduced = [];

      response.data.forEach((item) => {
          var duplicated  = reduced.findIndex(oldItem => {
              return item.name === oldItem.name;
          }) > -1;

          if(!duplicated) {
              reduced.push(item);
          }
      });

      const newOrder = reduced.sort((p1, p2) => (p1.name < p2.name) ? -1 : (p1.name > p2.name) ? 1 : 0);
      setfiltersToShow(newOrder);
    } catch(error) {
      alert('Erro ao buscar o credito')
      LogRocket.error('Error - Análise em lote - Ocorreu um erro ao buscar o credito', { error: JSON.stringify(error) });
    }
  }, [])


  const handleGetHistoryAnalysts = useCallback(async () => {
    try {
      const response = await getWallet(user.id)
      let data = [];
      response.data.map((item) => {
        if (item.receiveFile && (item.receiveFile.type !== 'pdf')) {
          data.push(item);
        }
      });
      setHistoryAnalysts(data);
    } catch(error) {
      alert('Erro ao buscar histórico')
      LogRocket.error('Error - Análise em lote - Ocorreu um erro ao buscar o histórico', { error: JSON.stringify(error) });

    }
  }, [user.id])


  useEffect(() => {
    handleGetCurrency()
    handleGetHistoryAnalysts()
    handleGetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
     <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
      <div className={styles.container}>
        <div className={styles.headerContent}>
          <h1 className={styles.title}>Análise de dados</h1>

          <div className={styles.availableCredit}>
            <p>Créditos disponivels para análise</p>

            {credit.value ? (
              <span>{formatToBRL(credit.value)}</span>
            )
          :
          (
            <span>{0}</span>
          )}


          </div>
        </div>


        <div className={styles.generalInfos}>
          <p>Olá, <span>{user.name}</span> Por  aqui você faz o upload do arquivo em Excel com seus CPFs que deseja analisar. Basta prencher a primeira coluna da planilha com os documentos que deseja analisar, inserindo um CPF por linha</p>

          <p>Caso tenha dúvidas sobre o formato do arquivo,  <span><a target="_blank" rel="noreferrer" href={"https://kollecta-wallet.s3.sa-east-1.amazonaws.com/wallet/Exemplo.csv"}> clique neste link</a></span>  onde terá acesso ao modelo.</p>

          <p> Antes de realizar suas análises, <span><a target="_blank" rel="noreferrer" href={"https://kollecta-wallet.s3.sa-east-1.amazonaws.com/wallet/%C3%8Dndice+de+an%C3%A1lises+(PFs)+-+kollecta.io.pdf"}> clique neste link</a></span> e entenda os aspectos de cada filtro que pode ser ultilizado</p>
        </div>

        <div className={styles.areaUpload}>

          <div className={styles.leftSection}>
            {dataFormAfterUse ? (
              <>
                <h2>1. Arquivo selecionado </h2>
                <img src={success} alt="warning" />
                {/* <UploadFile dataForm={dataForm} setDataFormAfterUse={setDataFormAfterUse} /> */}
              </>
            ) : (
              <>
                <h2>1. Upload de arquivos</h2>
                <UploadFile dataForm={dataForm} setDataFormAfterUse={setDataFormAfterUse} />
              </>
            )}

          </div>

          <div className={styles.rightSection}>
            <div className={styles.headerFilters}>

              <h2>2. Filtro de análise</h2>

              <div className={styles.select}>
                <select onChange={(e) => handleAddFilter(e.target.value)} className={styles.select}>
                  <option value="" selected >Selecione os critérios de sua análise</option>

                  {filtersToShow.map(item => (
                    <option
                      key={item.id}
                      disabled={filtersSelected.some(filterSelected => filterSelected.id === item.id)}
                      value={item.id}>{item.name}</option>
                  ))}

                </select>
              </div>
            </div>



            <div className={styles.selectedFilters}>
              <p>Filtros selecionados</p>

              <div className={styles.cardSelectedFilters}>

                {filtersSelected.map(item => (
                  <div key={item.id}>
                    <span>{item.name}  </span>
                    {loading ? (
                      <></>

                    ) : (
                      <span onClick={() => handleRemoveOption(item.id)}>X</span>
                    )}
                  </div>
                ))}
              </div>
              {loading ? (
                <button className={styles.btnSubmit} disabled>Carregando...</button>
              ) : (
                <button className={styles.btnSubmit} onClick={() => handleGenerateAnalysis()}>GERAR ANÁLISE</button>
              )}
            </div>
          </div>
        </div>




        <div className={styles.historyContainer}>
          <h2>Histórico de Análises</h2>
          <div className={styles.historySection}>
            {historyAnalysts.map(item => (
              <div className={styles.historyContent} key={item.id} onClick={() => {
                window.open(
                  `https://kollecta-wallet.s3.sa-east-1.amazonaws.com/wallet/${item.id}.xlsx`
                );
                // 
              }}>
                <div className={styles.historyDetails}>
                  <span>{item.name}.xls</span>
                  {/* <span>1,2kb/1,2kb</span> */}
                </div>
                <div>
                  <img src={isChecked} alt="Checked" />
                </div>
              </div>
            ))}
          </div>
        </div>

        {popUpFeedBack.loading && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, loading: !popUpFeedBack.loading })}
          >
            <div className={styles.modalContainer}>
              <p>
                Sua análise está em processamento e pode demorar alguns minutos para ser concluída
              </p>
              <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
            </div>
          </Modal>
        )}
        {popUpFeedBack.datesImported && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, datesImported: !popUpFeedBack.datesImported })}
          >
            <div className={styles.modalContainer}>

              <img src={success} alt="warning" />
              <p>
                Dados importados com sucesso!
              </p>
            </div>
          </Modal>
        )}
        {popUpFeedBack.success && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, success: !popUpFeedBack.success })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              {/* <h1>Titulo  do modal</h1> */}
              <img src={success} alt="warning" />

              <p>
                A sua análise foi gerada com sucesso!
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}        

      {popUpFeedBack.typeError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, apiError: false })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Tipo de arquivo inválido, deve ser do tipo csv
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}

        {popUpFeedBack.apiError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, apiError: false })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Erro ao consultar API, contate o suporte.
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}

        {popUpFeedBack.downloadError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, apiError: false })}
            doNotCloseOutside
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Erro ao criar o arquivo para o download, contate o suporte.
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}


        {toggleModal && (
          <Modal
            onClose={() => setToggleModal(!toggleModal)}
          >
            <div className={styles.modalContainer}>
              {/* <h1>Titulo  do modal</h1> */}
              <img src={cost.haveCredits ? warning : error} alt="warning" />

              {cost.haveCredits ? (
                <>
                  <p>
                    O custo de análise por CPF/CNPJ deste relatório será de <span>{formatToBRL(cost.unitCost)}</span>. Totalizando um custo total de  <span> {formatToBRL(parseFloat(cost.unitCost) * parseFloat(cost.documents+1))} </span> de seus créditos.
                  </p>

                  {loading ? (
                    <button className={styles.btnSubmit} disabled>Carregando...</button>
                  ) : (
                    <button className={styles.btnSubmit} onClick={() => handleAcceptAnalyst()}>Estou de acordo  e quero gerar análise</button>
                  )}


                </>
              ) : (
                <>
                  <p>O custo para gerar esta análise seria de <span> {formatToBRL(parseFloat(cost.unitCost) * parseFloat(cost.documents+1))}</span>, o <span> que excede os seus créditos atuais</span>.

                    <p>
                      Por favor, entre em contato para inserir novos créditos ou reduza o número de CPF/CNPJs da análise.</p>

                  </p>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default PortfolioAnalysis