import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';

import { parseCurrency } from '../../../utils/currency';
import { Button, Layout, Title, RadioButtonOffer } from '../../../Components';
import styles from './style.module.css';
import { pathOr } from 'ramda';
import LogRocket from 'logrocket';
import MbaLogo from '../../../assets/companiesLogos/mba.svg';
import moment from 'moment';
import PortalLayout from '../../../Components/PortalLayout';

const Offer = ({
  goToOffer,
  data,
  setInstallmentType,
  goToConfirmation,
  setDay,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedInstallments] = useState([]);
  const [showInstallments, setShowInstallments] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const moreInfoRef = useRef(null);

  LogRocket.identify(data.personalNumber);

  const customerName = pathOr(null, ["customerName"], data);
  const firstName = customerName && customerName.split(" ")[0];
 

  const handleChange = ({target}) => {
    const { value } = target;
    setIsChecked(value);
    setInstallmentType(value);
    setDay(0)
    if (value === 'parcelado') {
      setShowInstallments(true);
    } else {
      setShowInstallments(false);

    }
  }

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
    scrollToMoreInfo();
  };

  const scrollToMoreInfo = () => {
    if (showMoreInfo && moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const dates = [
    { description: 'Todo dia 1º de cada mês', value: 1 },
    { description: 'Todo dia 5º de cada mês', value: 5 },
    { description: 'Todo dia 10º de cada mês', value: 10 },
  ]

  const handleChangeInstallments = ({ target }) => {
    const { value } = target;
    isCheckedInstallments[0] = value;
    setDay(value)
  }
  

  useEffect(() => {
    if (showMoreInfo && moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showMoreInfo]);

  return (
    <PortalLayout>
      {/* # STEP 1  */}
      <div className={styles.offerContainer}>
        <div className={styles.top}>
          <div className={styles.topContent}>
            <h1> Oi {firstName}, você tem <span> 1 débito disponível </span> para negociar. </h1>
          </div>
        </div>
        
        <div className={styles.bigCard}>
          <div className={styles.headerCard}>
            <h4>Dívida da <span>{data?.companyDisplayName}</span></h4>
          </div>

          <div className={styles.topCard}>
            <div className={styles.topCardImage}>
            { data && data.companyId === '44aeda9c-25f2-435b-9d6f-4204860e5783' &&
                <img src={MbaLogo} alt="Kollecta" className={styles.compayLogo}></img>              
              }
            </div>

            <div className={styles.topCardProductInfo}>
              <div className={styles.topCardProductInfoItem}>
                <p> Produto negociado:  <h5> { data?.product || '-' } </h5> </p>
                <p> Contrato:  <h5> { data?.contract || '-' } </h5> </p>
                <p> Origem:  <h5> { data?.reason || '-' } </h5> </p>
              </div>
            </div>
          </div>

          <div className={styles.topCardRow}>
            <div className={styles.values}>
              <span> Valor atualizado da dívida: </span>
              <h6> De {parseCurrency(data?.originalAmount)} </h6>
            </div>

            <div className={styles.offValue}>
              <span> {data?.discount}% OFF </span>

              <h6> Por {parseCurrency(data?.discountAmount)} </h6>
            </div>
          </div>


          <div className={styles.options}>
            <section>
              <h1> Escolha abaixo como você quer pagar</h1>
              <RadioButtonOffer name="payment-offer" value="avista" onChange={handleChange} checked={isChecked === "avista" && true} customClass='offers'>
                <div className={styles.container}>
                  <div className={styles.left}>
                    <span> À vista (Boleto): </span>
                  <h5> {parseCurrency(data?.discountAmount)} </h5>
                  </div>
                </div>
              </RadioButtonOffer>          

              {data.entrance !== 0 &&
                <RadioButtonOffer name="payment-offer" value="parcelado" onChange={handleChange}  checked={isChecked === "parcelado" && true} customClass='offers'>
                  <div className={styles.container}>
                    <div className={styles.left}>
                      <span> Parcelado (Boleto): </span>
                      <h6>Entrada de {parseCurrency(data.entrance)}</h6>
                      <h5>{data.installments} x {parseCurrency(data.installmentAmount)}</h5>
                    </div>
                  </div>
                </RadioButtonOffer>
              }
            </section>

            {
              showInstallments && (
                <div className={styles.installmentsContainer}>
                  <h2> Escolha abaixo a data de vencimento dos boletos </h2>
                  {dates.map(({ description, value}) => (
                    <RadioButtonOffer
                      name="payment-due-at"
                      key={description}
                      value={value}
                      onChange={handleChangeInstallments}
                      checked={+isCheckedInstallments[0] === +value && true}
                      customClass='offers'
                    >
                      <Title color="primary" align="left">
                        {description}
                      </Title>
                    </RadioButtonOffer>
                  ))}
                </div>
              )
            }

              <div className={styles.cardBottom}>
                <Button onClick={() => goToConfirmation(isChecked, data?.id)} color="dark">
                  Quero essa
                </Button>
              </div>

              <div className={styles.cardBottom}>
                <button onClick={toggleMoreInfo} className={styles.linkButton}>
                  {showMoreInfo ? 'Menos informações do débito' : 'Mais informações do débito'}
                </button>

                {showMoreInfo && (
                  <div ref={moreInfoRef} 
                  className={`${styles.moreInfoContainer} ${showMoreInfo ? styles.moreInfoVisible : ''}`}>
                    <h4>
                      Produto negociado: <span>{data?.reason}</span>
                    </h4>
                    <h4>
                      Quem é a empresa que originou o débito? <span>{data?.companyExplanation}</span>
                    </h4>
                    <h4>
                      Data de origem da dívida: <span>{data?.validDueDate ? moment(data?.validDueDate).format('DD-MM-YYYY') : '-'}</span>
                    </h4>
                  </div>
                )}
              </div>

          </div>

        </div>
        </div>
    </PortalLayout>
  )
}

export default Offer;
