import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FeedbackContainer from '../../../Container/Mobile/Feedback';

const Feedback = () => {
  const [personalEmail, setPersonalEmail] = useState('')
  const [loading, setLoading] = useState(true)

  const companySettings = useSelector((state) => state.companySettings.settings);

  const handleChange = ({ target }) => {
    setPersonalEmail(target.value)
  }

  const sendEmail = async () => {
    if (!personalEmail) {
      return;
    }
    try {
      console.log(personalEmail, 'E-mail será enviado aqui!');
    } catch (error) {
      console.error(error);
    }
  }
 
  return (
    <FeedbackContainer
      handleChange={handleChange}
      personalEmail={personalEmail}
      settings={companySettings}
      sendEmail={sendEmail}
      loading={loading}
    />
  )
}

export default Feedback;
