import React from 'react'
import NoOfferContainer from '../../../Container/Mobile/NoOffer'

const NoOffer = () => {
  return (
    <NoOfferContainer />
  )
}

export default NoOffer
