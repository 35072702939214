import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { useNavigate } from 'react-router-dom'
import OfferContainer from '../../../Container/Mobile/Offer'

const Offer = () => {
  const navigate = useNavigate()
  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const [day, setDay] = useState(1);
  const [installmentType, setInstallmentType] = useState('avista')

  const goToOffer = () => {
    if(installmentType === 'avista') {
      return navigate(`payment-methods/${pathOr(null, ["id"], pastDue)}/installments/${installmentType}`)

    }
   
    return navigate(`past-due-date/${installmentType}/payment/${pathOr(null, ["id"], pastDue)}`)    
  }
;
  
  const goToConfirmation = (paymentOption, pastDueId) => {
    return navigate(
      `past-due-date/${paymentOption}/payment/${pastDueId}/installment-day/${day}`
    )    
  }

  return (
    <OfferContainer
      goToOffer={goToOffer}
      data={pastDue}
      setInstallmentType={setInstallmentType}
      goToConfirmation={goToConfirmation}
      setDay={setDay}
    />
  )
}

export default Offer
