import React, { useState, useEffect, useRef } from 'react';
import CustomDatePicker from '../DatePicker/';
import styles from './style.module.css';
import { HiOutlineSearch } from 'react-icons/hi';

const DateFilter = ({ onDateChange }) => {
  const [dates, setDates] = useState({startDate: null, endDate: null, filter: null});
  const [showSingleDatePicker, setShowSingleDatePicker] = useState(false);
  const [showIntervalDatePickers, setShowIntervalDatePickers] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    if (onDateChange) {
      onDateChange(dates);
    }
  }, [dates, onDateChange]);

  const handleFilter = (filterType) => {
    const now = new Date();
    let newDates;

    switch (filterType) {
      case 'today':
        newDates = {startDate: now, endDate: now, filter: 'today'};
        break;
      case 'yesterday':
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        newDates = {startDate: yesterday, endDate: yesterday, filter: 'yesterday'};
        break;
      case 'next7days':
        const next7days = new Date(now);
        next7days.setDate(now.getDate() + 7);
        newDates = {startDate: now, endDate: next7days, filter: 'next7days'};
        break;
      case 'next10days':
        const next10days = new Date(now);
        next10days.setDate(now.getDate() + 10);
        newDates = {startDate: now, endDate: next10days, filter: 'next10days'};
        break;
      case 'next15days':
        const next15days = new Date(now);
        next15days.setDate(now.getDate() + 15);
        newDates = {startDate: now, endDate: next15days, filter: 'next15days'};
        break;
      case 'specific':
        setShowSingleDatePicker(true);
        setShowIntervalDatePickers(false);
        return;
      case 'interval':
        setShowSingleDatePicker(false);
        setShowIntervalDatePickers(true);
        return;
      default:
        newDates = {startDate: null, endDate: null, filter: null};
    }

    if (JSON.stringify(dates) !== JSON.stringify(newDates)) {
      setDates(newDates);
    }

    setShowIntervalDatePickers(false);
    setShowSingleDatePicker(false);
  }

  const handleSpecificDateChange = (date) => {
    if (date !== dates.startDate) {
      setDates({startDate: date, endDate: date, filter: 'specific'});
    }
  };

  const handleStartDateChange = (date) => {
    if (date !== dates.startDate) {
      setDates(prev => ({...prev, startDate: date, filter: 'interval'}));
    }
  };

  const handleEndDateChange = (date) => {
    if (date !== dates.endDate) {
      setDates(prev => ({...prev, endDate: date, filter: 'interval'}));
    }
  };

  return (
    <div  className={styles.wrapper}>
      <select ref={selectRef} onChange={(e) => handleFilter(e.target.value)}>
        <option value="">Selecione um filtro</option>
        <option value="today">Hoje</option>
        <option value="yesterday">Ontem</option>
        <option value="next7days">Daqui a 7 dias</option>
        <option value="next10days">Daqui a 10 dias</option>
        <option value="next15days">Daqui a 15 dias</option>
        <option value="interval">Intervalo</option>
        <option value="specific">Específico</option>
      </select>

      { (showSingleDatePicker || showIntervalDatePickers) &&
        <div className={styles.datesModalContainer}>
          {showSingleDatePicker &&
            <div className={styles.specificPickerWrapper}>
              <CustomDatePicker onDateChange={handleSpecificDateChange} />
            </div>
          }

          {showIntervalDatePickers &&
            <div className={styles.intervalPickerWrapper}>
              <div className={styles.dateContainer}>
                <label>De</label>
                <CustomDatePicker onDateChange={handleStartDateChange} />
              </div>
              <div className={styles.dateContainer}>
                <label>Até</label>
                <CustomDatePicker onDateChange={handleEndDateChange} />
              </div>
              <button className={styles.searchButton} onClick={() => { onDateChange(dates);  setShowIntervalDatePickers(false);  if (selectRef.current) {
        selectRef.current.value = "";
    } }}>
                <i className={styles.searchIcon}><HiOutlineSearch color="#fff" /></i>
              </button>
            </div>
          }

        </div>     
      
      }
    </div>
  )
}

export default DateFilter;
