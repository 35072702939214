import React from 'react';
import { useNavigate  } from 'react-router-dom';
import styles from './style.module.css';

const GoBack = () => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    return navigate(-1);;
  };

  return (
    <div
      className={styles.goBack}
      onClick={handleClick}
    />
  );
};

export default GoBack;