import React from 'react';
import { Button, Layout, Title, RadioButton } from '../../../Components';
import styles from './style.module.css';
import Boleto from '../../../assets/images/copy.png';
import Email from '../../../assets/images/email.png';
import Arrow from '../../../assets/images/arrow.svg';
import { parseCurrency } from '../../../utils/currency';
import { pathOr } from 'ramda';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const BilletMethods = ({
  setBilletMethod,
  installmentDay,
  goToCodeOrEmail
}) => {

  const [isChecked, setIsChecked] = useState(false);

  const billetOptions = [
    { icon: Email, label: 'Boleto por e-mail', description: 'Boleto por e-mail',  value: 'email' },
    { icon: Boleto, label: 'Código de barras', description: 'Código de barras', value: 'barcode' }
  ];

  const pastDue = useSelector((state) => state.pastDue.currentPastdue);
  const customerName = pathOr(null, ["customerName"], pastDue)
  const firstName = customerName && customerName.split(" ")[0]; 
  

  const handleChange = ({ target }) => {
    const { value } = target;
    setIsChecked(value);
    setBilletMethod(value);
  }
  return (
    <Layout>
      {!installmentDay && (
          <div className={styles.paymentContainer}>
             <Title size="medium">
             {firstName}, você está perto de quitar sua pendência!
             </Title>
     
             <Title size="medium">
               Pagamento à vista de  {parseCurrency(pathOr(0, ["discountAmount"], pastDue))}
             </Title>

             <div className={styles.radioGroup}>
              {billetOptions.map(({ icon, label, value, description }) => (
                <RadioButton key={label} icon={icon} value={value} description={description} customStyle="column" onChange={(event) => handleChange(event)} checked={isChecked === value && true} />
              ))}
             </div>
           </div>
      )}

      {installmentDay && (
              <div className={styles.paymentContainer}>
                <Title size="medium">
                Você pagará uma <strong>entrada no valor de {parseCurrency(pathOr(0, ["entrance"], pastDue))} e {pathOr(0, ["installments"], pastDue)} parcelas de {parseCurrency(pathOr(0, ["installmentAmount"], pastDue))}</strong>
                </Title>

                <Title size="medium">
                Vamos te relembrar de pagar sempre próximo ao vencimento, mas não deixe de pagar a entrada para iniciarmos o processo de quitação de suas dívidas!
                </Title>

                <Title size="medium">
                Vamos lá?
                </Title>

               <div className={styles.radioGroup}>
                {billetOptions.map(({ icon, label, value, description }) => (
                    <RadioButton name="payment-method" key={label} description={description} customStyle="column"  icon={icon} value={value} onChange={handleChange} checked={isChecked === value && true} />
                  ))}
               </div>                
              </div>
          )}


      <div style={{ margin: '30px 0', textAlign: 'center' }}>
        <Button onClick={goToCodeOrEmail} >
          Gerar boleto 
          <img style={{margin: '0 0 0 10px'}} src={Arrow} alt="arrow-right" height="10px" />
        </Button>
      </div>
    </Layout>
  )
}

export default BilletMethods;
