import React from 'react';
import styles from './style.module.css';
import classnames from 'classnames';

function Select(props) {
  const { options, value, onChange, classCustom, label, fullWidth } = props;

  const combinedClasses = classnames(
    styles.wrapper,
    { [styles.fullWidth]: fullWidth },
    { [styles[classCustom]]: classCustom }
  );

  return (
    <div className={styles.content}>
      {label && <label>{label}</label>}
      <div className={combinedClasses}>
        <select value={value} onChange={onChange} defaultValue={''}>
          <option value="" disabled>Selecione</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Select;
