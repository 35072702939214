import React from 'react';
import styles from './style.module.css';

const Card = ({ children, noPadding, padding25 }) => {
  return (
    <div className={`${styles.wrapper} ${noPadding ? styles.noPadding : ''} ${padding25 ? styles.padding25 : ''}`}>
      { children }
    </div>
  )
}

export default Card;
