import { CPF, CNPJ } from 'cpf_cnpj';

const formatDocument = document => {
  let adjustedDocument = document;

 if (document.length < 11) {
    adjustedDocument = document.padStart(11, '0');
  }

  if (CNPJ.isValid(adjustedDocument)) {
    return CNPJ.format(adjustedDocument);
  }

  return CPF.format(adjustedDocument);
};

export default formatDocument;
