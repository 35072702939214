import React from 'react'
import classNames from 'classnames'
import styles from './style.module.css'

const Alert = ({ children, show }) => {
  return (
    <div className={classNames(styles.alert, { [styles.show]: show })}>
      {children}
    </div>
  )
}

export default Alert
