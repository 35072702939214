import { createSlice } from '@reduxjs/toolkit'

export const pastDue = createSlice({
  name: 'pastdue',
  initialState: {
    currentPastdue: {}
  },
  reducers: {
    setPastdue: (state, action) => {
      state.currentPastdue = action.payload
    },
    reset: (state) => {
      state.currentPastdue = {}
    },
  },
})

export const { 
  setPastdue, 
  reset,
} = pastDue.actions

export default pastDue.reducer
