
import styles from './style.module.css'

const Modal = ({ id = 'modal', onClose = () => { }, children, doNotCloseOutside }) => {

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose()
  }

  return (
    <div id={id} className={styles.modal} onClick={() => doNotCloseOutside ? handleOutsideClick : {}}>
      <div className={styles.container}>

        {!doNotCloseOutside && (
          <button className={styles.close} onClick={onClose} />
        )}

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default Modal