import React, { useState } from 'react';
import styles from './style.module.css';

const StatusSelector = ({ selectedStatus, handleStatusChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.selectorContainer}>
      {label && <label htmlFor="statusSelect">{label}</label>}
      <div className={isOpen ? `${styles.selectWrapper} ${styles.active}` : `${styles.selectWrapper}`} 
          onClick={handleClick}>
        <select id="statusSelect" value={selectedStatus} onChange={handleStatusChange} className={styles.input}>
          <option value="">Selecione um status</option>
          <option value="agreement_broken">Acordo quebrado</option>
          <option value="generated">Acordo emitido</option>
          <option value="paid">Acordo quitado</option>
          <option value="pending">Acordo em progresso</option>
          <option value="viewed">Acordo consultado</option>
          <option value="collections_suspended">Cobranças suspensas</option>
          <option value="contacted">Contato realizado</option>
          <option value="scheduled_contact">Contato agendado</option>
          <option value="cpc">CPC</option>
          <option value="cpe">CPE</option>
          <option value="deceased">Falecido</option>
          <option value="deny_pending">Não reconhece pendência</option>
          <option value="new_condition">Nova condição</option>
          <option value="indirect_payment">Pagamento indireto</option>
          <option value="waiting_payment">Pendente</option>
          <option value="promise_to_pay">Promessa de pagamento</option>
          <option value="confirm_pending">Reconhece pendência</option>
          <option value="no_interest">Sem interesse na proposta</option>
          <option value="not_return">Sem retorno</option>
          <option value="without_terms_payment">Sem condições de pagamento</option>
          <option value="suspected_fraud">Suspeita de fraude</option>
        </select>
      </div>
    </div>
  );
};

export default StatusSelector;