import React from 'react';

function IconArrowDown(props) {
  const {
    fill,
    width,
    height,
  } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.74473 5.99925C6.74473 6.27318 6.5191 6.49525 6.24076 6.49525L1.70505 6.49525C1.42671 6.49525 1.20108 6.27318 1.20108 5.99925C1.20108 5.72532 1.42671 5.50325 1.70505 5.50325L5.73679 5.50325L5.73679 1.53525C5.73679 1.26132 5.96243 1.03925 6.24076 1.03925C6.5191 1.03925 6.74473 1.26132 6.74473 1.53525L6.74473 5.99925ZM1.6525 0.782088L6.59712 5.64853L5.8844 6.34998L0.939783 1.48354L1.6525 0.782088Z" fill="#532517"/>
    </svg>    
    
  );
}

export default IconArrowDown;
