import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from './style.module.css';
import { BiCalendarAlt } from 'react-icons/bi';

import "react-datepicker/dist/react-datepicker.css";

const DateRangeSelector = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onDateChange && start && end) { 
      onDateChange({ start, end });
    }
    if(start && end) {
      setIsOpen(false);
    }
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const dateDisplay = startDate && endDate 
  ? `${startDate.toLocaleDateString('default', { month: 'numeric', day: 'numeric' })} a ${endDate.toLocaleDateString('default', { month: 'numeric', day: 'numeric' })}`
  : "Selecione as datas";

  return (
    <div className={styles.wrapper}>
      <button className={styles.dateButton} onClick={toggleOpen}>
        {dateDisplay} <BiCalendarAlt /> 
      </button>

      {isOpen && (
        <div className={styles.datepickerWrapper}>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
