const statusSettings = [
  { value: 'collections_suspended', label: 'Cobranças suspensas' },
  { value: 'contacted', label: 'Contato realizado' },
  { value: 'cpc', label: 'CPC' },
  { value: 'cpe', label: 'CPE' },
  { value: 'deceased', label: 'Falecido' },
  { value: 'deny_pending', label: 'Não reconhece pendência' },
  { value: 'indirect_payment', label: 'Pagamento indireto' },
  { value: 'waiting_payment', label: 'Pendente' },
  { value: 'confirm_pending', label: 'Reconhece pendência' },
  { value: 'no_interest', label: 'Sem interesse na proposta' },
  { value: 'not_return', label: 'Sem retorno' },
  { value: 'without_terms_payment', label: 'Sem condições de pagamento' },
  { value: 'suspected_fraud', label: 'Suspeita de fraude' }
]

export default statusSettings;
