import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import HomeContainer from '../../../Container/Mobile/Home'
import { getOffers, updatePastDue, createPastdueLog } from '../../../services/kollectaApi.js'
import { setPastdue } from '../../../Redux/Reducers/PastDue'
import ReactGA from 'react-ga';

const Home = () => {
  const [personalNumber, setPersonalNumber] = useState('')
  const [loading, setLoading] = useState(true)

  const companySettings = useSelector((state) => state.companySettings.settings)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    setPersonalNumber(target.value)
  }

 const goToOffer = async () => {
  ReactGA.event({
    category: 'Botão',
    action: 'Clique',
    label: 'Consultar grátis'
  });
  
  if (!personalNumber) {
    return;
  }

  const sanitizedPersonalNumber = personalNumber.replace(/\D/g, '');
  try {
    let count = 0;
    let rows = [];

    // Realiza a busca por ofertas
    if (companySettings?.siteUrl) {
      const { data: { count: fetchedCount = 0, rows: fetchedRows = [] } } = await getOffers(sanitizedPersonalNumber, companySettings?.id);
      count = fetchedCount;
      rows = fetchedRows;
    } else {
      const { data: { count: fetchedCount = 0, rows: fetchedRows = [] } } = await getOffers(sanitizedPersonalNumber);
      count = fetchedCount;
      rows = fetchedRows;
    }

    if (count > 0) {
      const status = { status: "viewed" };

      // Atualiza todas as pastDues para o status "viewed"
      for (const row of rows) {
        if (row.status !== 'paid' || row.status !== 'generated') {
          try {
            await updatePastDue(row.id, status);
          } catch (error) {
            console.log(`Error updating pastDue ${row.id}:`, error);
          }
        }

        // Verificação de status "paid"
        if (row.status === 'paid') {
          navigate(`${sanitizedPersonalNumber}/no-offer`);
          return;
        }
      }

      // Armazena e navega para a próxima página
      dispatch(setPastdue(rows[0]));
      navigate(sanitizedPersonalNumber);
    } else {
      navigate(`${sanitizedPersonalNumber}/no-offer?personalNumber=${sanitizedPersonalNumber}`);
    }
  } catch (error) {
    navigate(`${sanitizedPersonalNumber}/no-offer?personalNumber=${sanitizedPersonalNumber}`);
  }
}

  setTimeout(() => setLoading(false), 3000);
  
  return (
    <HomeContainer
      handleChange={handleChange}
      personalNumber={personalNumber}
      goToOffer={goToOffer}
      settings={companySettings}
      loading={loading}
      companySettings={companySettings}
    />
  )
}

export default Home;
