import React from 'react';
import styles from './style.module.css';
import  logo from './kollectalogo.svg';
import { useSelector } from 'react-redux';

const PortalFooter = () => {
  const companySettings = useSelector((state) => state.companySettings.settings);

  if (companySettings.siteUrl) {
    return (   
      <footer className={styles.wrapperWhitelabel}>
        <div>
          <p> Intermediado por </p>
          <img src={logo} alt='Kollecta' className={styles.companyLogo} />
        </div>
        <div>
          <p className={styles.copy}> KOLLECTA TECNOLOGIA LTDA </p> 
          <p>CNPJ: 44.528.091/0001-08 </p>          
        </div>
      </footer>
    )
  }

  return (   
    <footer className={styles.wrapper}>
      <img src={logo} alt='Kollecta' className={styles.companyLogo} />
      <p className={styles.copy}> KOLLECTA TECNOLOGIA LTDA | CNPJ: 44.528.091/0001-08 </p>
    </footer>
  )
}

export default PortalFooter;
