import React from 'react'
import styles from './style.module.css'
import { UploadFileAndList } from '../../../Components'
import exampleFile from './example.xlsx'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import pastDueImage from '../../../assets/images/past-dues-image.svg';

const PastDue = () => {
  return (
    <div className={styles.container}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <section>
          <div className={styles.top}>
            <h3 className={styles.titlePastDueDash}>Cadastro de dívidas</h3>
          </div>
          <div  className={styles.paddingContent}>
            <div className={styles.infoCustomer}>
              <h4 className={styles.subtitlePastDueDashCustomer}>Olá, {localStorage.getItem('user.name')}</h4>
              <h5 className={styles.smallMargin}> 
              Por aqui você faz o upload do arquivo em Excel com as informações das dívidas que ficarão cadastradas em seu portal de autonegociação.
              </h5>

              <h5> 
                Caso tenha dúvidas sobre o formato do arquivo, <a target="_blank" rel="noreferrer" href={exampleFile}>clique neste link</a> onde terá acesso ao modelo.
              </h5>
            </div>

            <div className={styles.uploadDocument}>
              <div className={styles.uploadDocumentContent}>
                <div className={styles.imageContainer}>
                  <img src={pastDueImage} alt="Pessoa cadastrando dívidas no painel" />
                </div>
                <UploadFileAndList showList />
              </div>
            </div>
          </div>
        </section>
      </ReactCSSTransitionGroup>
    </div>
  )
}

export default PastDue
