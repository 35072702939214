import React from 'react';
import styles from './style.module.css';
import TeamIcon from '../Arrow';

const TeamTable = ({ data }) => {
  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          <th>Pessoa negociadora</th>
          <th>Nº acionamentos</th>
          <th>Promessas de pgt</th>
          <th>Acordos emitidos</th>
          <th>Vol. Pagos</th>
          <th>Meta vol. Pagos</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.actionCount}</td>
            <td>{ item.promiseAmount ? `${item.promiseAmount}`: '-'}</td>
            <td>{ item.agreementsIssued ? `${item.agreementsIssued}`: '-'}</td>
            <td>{ item.totalPaidAmount ? `${item.totalPaidAmount}`: '-'}</td>
            <td>
              <TeamIcon value={item.realizationPercentage} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TeamTable;
