import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getPastDues, getPastDuesByGroup } from '../../../services/kollectaApi';
import ManagerPastDuesContainer from '../../../Container/Dashboard/ManagerPastDues';
import formatDocument from '../../../utils/cpf_cnpj';
import parseStatus from '../../../utils/statusParse';

const tbSettings = [
  { path: 'personalNumber', center: false, headerName: 'Documento', columnFormat: formatDocument},
  { path: 'customerName', center: false, headerName: 'Nome' },
  { path: 'companyDisplayName', center: false, headerName: 'Credor' },
  { path: 'updatedAt', center: true, headerName: <>Última atualização</>, columnFormat: date => moment(date).format("DD/MM/YYYY")},
  { path: 'promisePaymentDate', center: true, headerName: <>Data futura</>, columnFormat: (date) => { 
    if (date.length > 5) {
      return moment(date).format("DD/MM/YYYY");
    }
    return '--';
  }},
  { path: 'status', center: true, headerName: 'Status', columnFormat: parseStatus },
  { path: 'details', center: true, headerName: 'Detalhes' },
]

const initialValue = { count: 0, rows: [] }
const ManagerPastDues = () => {
  const [data, setData] = useState(initialValue)

  useEffect(() => {
    getPastDuesList()
  }, [])

  const getPastDuesList = async () => {
    try {
      const { data = initialValue } = await getPastDuesByGroup(1);
      setData(data)
    } catch (error) {
    }
  }

  return (
    <ManagerPastDuesContainer
      data={data}
      tbSettings={tbSettings}
    />
  )
}

export default ManagerPastDues
